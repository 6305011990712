import * as History from 'history';
import { action } from 'mobx';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import qs from 'qs';

import { replaceUrlParams } from 'http/helpers';

import ModalStore from './ModalStore';

class AppRouter {
  browserHistory = History.createBrowserHistory();
  router = new RouterStore();
  synchronizedHistory = syncHistoryWithStore(this.browserHistory, this.router);

  constructor() {
    this.browserHistory.listen(() => ModalStore.closeAllModal());
  }

  push(url: string, data?: object, params?: object) {
    let path = data ? replaceUrlParams(url, data) : url;

    if (params) {
      path = path + '?' + qs.stringify(params);
    }

    this.router.push(path);
  }

  @action.bound
  goBack() {
    this.router.goBack();
  }

  getHashValue() {
    return window.location.hash ? window.location.hash.slice(1) : undefined;
  }

  addParams(url: string, newParams: object): string {
    const [origin, paramsStr] = url.split('?');
    const params = { ...qs.parse(paramsStr), ...newParams };

    return origin + '?' + qs.stringify(params);
  }
}

export default new AppRouter();
