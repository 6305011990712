import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import style from './ChangePassword.module.scss';

@observer
class ValidationMessage extends Component<{
  isValid: boolean;
  message: string;
}> {
  render() {
    const { isValid, message } = this.props;
    return (
      <div
        className={classNames(style.validation__message, {
          [style.validation__message__invalid]:
            !isValid && isValid !== undefined,
          [style.validation__message__valid]: isValid,
        })}
      >
        <FormattedMessage id={message} />
        {((!isValid && isValid !== undefined) || isValid) && (
          <Icon type={this.props.isValid ? 'iconOk' : 'iconNotOk'} />
        )}
      </div>
    );
  }
}

export default ValidationMessage;
