import { defineMessages, IntlShape } from 'react-intl';

export function formatMessages(intl: IntlShape) {
  return function formatMessage(key: string) {
    const messages = defineMessages({
      [key]: {
        id: key,
      },
    });

    return intl.formatMessage(messages[key]);
  };
}
