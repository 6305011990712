import ModalStore from 'stores/ModalStore';
import ErrorModal from 'components/Modals/ErrorModal/ErrorModal';
import { AxiosResponse } from 'axios';
import Log from './log';

interface ErrorData {
  key: string;
  params: any;
}

interface ServerErrorData {
  message: string;
  params: any[];
}

interface HttpErrorData {
  errors: ServerErrorData[];
}

interface AxiosError {
  response: AxiosResponse<HttpErrorData>;
}

export function showErrors(error: ErrorData) {
  return ModalStore.showModal(ErrorModal, {
    error,
    cancelBtnText: 'button.ok',
  });
}

export function showHttpErrors(errors: AxiosError) {
  if (
    errors &&
    errors.response &&
    (errors.response.status === 403 || errors.response.status === 401)
  ) {
    // Ignore, work interceptor
    return;
  }

  Log.info(errors);

  return ModalStore.showModal(ErrorModal, {
    error: getHttpError(errors),
    cancelBtnText: 'button.ok',
  });
}

export function getHttpError(errors: AxiosError) {
  if (!errors.response) {
    return {
      key: 'error.connection.timeout',
      params: {},
    };
  }

  if (errors.response.status === 403) {
    return {
      key: 'error.403.text',
      params: {},
    };
  }

  return errors.response.data.errors && errors.response.data.errors.length
    ? {
        key: errors.response.data.errors[0].message,
        params: {}, // TODO: errors.data.errors[0].params,
      }
    : { key: 'Error' };
}

export function getError(errors: Error) {
  return {
    key: errors.message,
    params: {},
  };
}
