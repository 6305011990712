export type Time = number;

export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = 60 * 1000;
export const MILLISECONDS_PER_HOUR = 60 * MILLISECONDS_PER_MINUTE;
export const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;

export function getTime(
  hours: number,
  minutes: number = 0,
  seconds: number = 0,
  milliseconds: number = 0,
): Time {
  // return Date.UTC(1970, 0, 1, hours, minutes, seconds, milliseconds);
  return (
    hours * MILLISECONDS_PER_HOUR +
    minutes * MILLISECONDS_PER_MINUTE +
    seconds * MILLISECONDS_PER_SECOND +
    milliseconds
  );
}

export function getTimeFromDate(date: Date): Time {
  return getTime(date.getHours(), date.getMinutes(), date.getSeconds());
}

export function setTimeToDate(date: Date, time: Time): Date {
  const result = new Date(date);

  result.setHours(0);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(time);

  return result;
}

// time format: 'hh:mm'
export function stringToTime(time: string): Time {
  const [hours, minutes] = time.split(':');
  return getTime(+hours || 0, +minutes || 0);
}

export function stringToDateTime(dateTime: string): Date {
  const dateTimeISO = dateTime.endsWith('Z') ? dateTime : dateTime + 'Z';

  return new Date(dateTimeISO);
}

export function dateAdd(
  date: Date,
  count: number,
  period: 'year' | 'month' | 'week' | 'hour' | 'day' | 'minute' | 'second',
): Date {
  const result = new Date(date);

  switch (period) {
    case 'year':
      result.setFullYear(result.getFullYear() + count);
      break;
    case 'month':
      result.setMonth(result.getMonth() + count);
      break;
    case 'week':
      result.setDate(result.getDate() + 7 * count);
      break;
    case 'day':
      result.setDate(result.getDate() + count);
      break;
    case 'hour':
      result.setHours(result.getHours() + count);
      break;
    case 'minute':
      result.setMinutes(result.getMinutes() + count);
      break;
    case 'second':
      result.setSeconds(result.getSeconds() + count);
      break;
  }

  return result;
}

export function dateStartOf(
  date: Date,
  period: 'month' | 'week' | 'hour' | 'day' | 'minute' | 'second',
): Date {
  const result = new Date(date);

  if (period === 'week') {
    result.setDate(result.getDate() - ((result.getDay() + 6) % 7));
  }

  /* eslint-disable no-fallthrough */
  switch (period) {
    case 'month':
      result.setDate(1);
    case 'week':
    case 'day':
      result.setHours(0);
    case 'hour':
      result.setMinutes(0);
    case 'minute':
      result.setSeconds(0);
    case 'second':
      result.setMilliseconds(0);
  }
  /* eslint-enable no-fallthrough */

  return result;
}

export function dateEndOf(
  date: Date,
  period: 'month' | 'week' | 'hour' | 'day' | 'minute' | 'second',
): Date {
  const result = new Date(date);

  if (period === 'week') {
    result.setDate(result.getDate() + ((7 - result.getDay()) % 7));
  }

  /* eslint-disable no-fallthrough */
  switch (period) {
    case 'month':
      result.setDate(1);
      result.setMonth(result.getMonth() + 1);
      result.setDate(0);
    case 'week':
    case 'day':
      result.setHours(23);
    case 'hour':
      result.setMinutes(59);
    case 'minute':
      result.setSeconds(59);
    case 'second':
      result.setMilliseconds(999);
  }
  /* eslint-enable no-fallthrough */

  return result;
}

export function dateToString(
  dateTime: Date,
  delimiter = '-',
  revers = false,
): string {
  const day = dateTime
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
  const year = dateTime.getFullYear();

  return revers
    ? [day, month, year].join(delimiter)
    : [year, month, day].join(delimiter);
}

export function getISODate(date: Date) {
  return date.toISOString().slice(0, 10);
}
