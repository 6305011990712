import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';

import style from './AppProfile.module.scss';

import { ROUTES } from 'routes/routes';

import Icon from 'components/Icon/Icon';

const AppProfile = ({ user, className = '' }) => (
  <div className={classNames(style.profile, className)}>
    <NavLink
      to={ROUTES.profile}
      className={classNames(style.link, style.linkProfile)}
      activeClassName={style.link__active}
    >
      <Icon type="profile" />
    </NavLink>

    <span className={style.name}>{user.username}</span>

    <Link to={ROUTES.login} className={style.linkWrapper} onClick={user.logOut}>
      <Icon type="logout" className={style.icon} />
    </Link>
  </div>
);

export default AppProfile;
