import React from 'react';
import { FormattedMessage } from 'react-intl';
import { uniqueId } from 'helpers/uniqueId';
import { RoomPrice } from 'types/entities';

import style from './RoomInfoModal.module.scss';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

const PriceTiers = ({ priceTiers }: { priceTiers: RoomPrice }) => {
  return (
    <ul className={style.info__value}>
      {priceTiers.tiers.map((it, index) => {
        return (
          <li key={uniqueId()} className={style.cost__value}>
            {it.maxDurationMins ? (
              it.price !== priceTiers.tiers[index + 1]?.price &&
              (it.maxDurationMins === 240 ? (
                <>
                  &#x00BD; &nbsp;
                  <FormattedMessage
                    id="modal.roomInfo.cost.half.up.to"
                    values={{
                      hours: it.maxDurationMins / 60,
                      cost: <FormattedCurrency value={it.price} />,
                    }}
                    tagName={React.Fragment}
                  />
                </>
              ) : (
                <FormattedMessage
                  id="modal.roomInfo.cost.up.to"
                  values={{
                    hours: it.maxDurationMins / 60,
                    cost: <FormattedCurrency value={it.price} />,
                  }}
                  tagName={React.Fragment}
                />
              ))
            ) : it.price === 0 ? (
              <FormattedCurrency value={it.price} />
            ) : (
              <FormattedMessage
                id="modal.roomInfo.capacity.more.than"
                values={{
                  hours: priceTiers.tiers[index - 1].maxDurationMins / 60,
                  cost: <FormattedCurrency value={it.price} />,
                }}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default PriceTiers;
