import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';

import style from '../Modals.module.scss';

import Button from 'components/Button/Button';

import { ModalProps, DialogActionType } from 'stores/ModalStore';

interface Props extends ModalProps {
  message: string;
  continueText: string;
  canceledText: string;
}

@observer
class PreventModal extends Component<Props> {
  onContinue = () => {
    this.props.onClose(DialogActionType.submit);
  };

  onCancel = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  render() {
    const { message, continueText, canceledText } = this.props;

    return (
      <Modal
        className={style.modal__condition}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={this.onCancel}
        visible
      >
        <div className={style.text}>
          <FormattedMessage id={message} />
        </div>

        <div className={style.buttons}>
          <Button
            className={style.btn}
            styleType="ghost"
            onClick={this.onCancel}
          >
            <FormattedMessage id={canceledText} />
          </Button>

          <Button
            className={style.btn}
            styleType="ghost"
            onClick={this.onContinue}
          >
            <FormattedMessage id={continueText} />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default PreventModal;
