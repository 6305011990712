import http from '../http';

import { appUrl, filterEmptyFields } from '../helpers';
import { dateToString } from 'helpers/datetime';

import URLS from '../urls';

import { ReportModel } from 'stores/Models/ReportModel';
import { ReportFilter } from '../../types/entities';

export const reportApi = {
  reports([start, end]: Date[], filter: ReportFilter) {
    const startDate = dateToString(start);
    const endDate = dateToString(end);

    return http.get<ReportModel>(appUrl(URLS.reports), {
      params: { startDate, endDate, ...filterEmptyFields(filter) },
    });
  },

  fetchReportPDF([start, end]: Date[], filter: ReportFilter) {
    const startDate = dateToString(start);
    const endDate = dateToString(end);

    return http.get<any>(appUrl(URLS.reportPDF), {
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
      params: { startDate, endDate, ...filterEmptyFields(filter) },
    });
  },
};

export default reportApi;
