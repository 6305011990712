import React from 'react';
import classNames from 'classnames';

import { Omit } from 'helpers/types';

import style from './TextField.module.scss';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  invalid?: boolean;
  onChange?: (v: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}

class TextField extends React.Component<Props> {
  onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChange && this.props.onChange(event.target.value, event);

  render() {
    const { invalid, ...props } = this.props;

    return (
      <input
        {...props}
        className={classNames(
          style.field,
          invalid && style.field__invalid,
          this.props.className,
        )}
        onChange={this.onChange}
      />
    );
  }
}

export default TextField;
