import React from 'react';
import { action, computed, observable } from 'mobx';
import { toast } from 'react-toastify';

import Notification from 'components/Notifications/Notification';

import { MILLISECONDS_PER_MINUTE } from 'helpers/datetime';
import { showHttpErrors } from 'helpers/errors';
import Log from 'helpers/log';

import httpFacade from 'http/httpFacade';

import { NotificationModel, NotificationType } from 'types/notification';

const REQUEST_INTERVAL = 3 * MILLISECONDS_PER_MINUTE;

class NotificationStore {
  @observable data: NotificationModel[] = [];

  lastNotificationId?: string;
  config = {
    className: 'notification',
    autoClose: 10000,
    draggable: false,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
  };
  timer: number;

  start() {
    this.fetchNotifications();
    this.timer = window.setInterval(this.fetchNotifications, REQUEST_INTERVAL);
  }

  @action.bound
  stop() {
    clearInterval(this.timer);

    this.data = [];
    this.lastNotificationId = undefined;
  }

  countNotificationByType(type: NotificationType) {
    return computed(
      () => this.data.filter(notification => notification.type === type).length,
    );
  }

  showLatestNotifications = (
    type: NotificationType | null = null,
    max: number = 3,
  ) => {
    const notificationByType =
      type === null
        ? this.data
        : this.data.filter(notification => notification.type === type);

    toast.dismiss();

    return notificationByType.slice(0, max).map(this.notify);
  };

  @action.bound
  async fetchNotifications() {
    try {
      const response = await httpFacade.notification.fetchNotifications(
        this.lastNotificationId,
      );

      if (response.data.length) {
        this.data = [...this.data, ...response.data];
        this.lastNotificationId = response.data[response.data.length - 1].id;

        this.showLatestNotifications(null);
      }
    } catch (error) {
      Log.warn(error);
    }
  }

  @action.bound
  async acceptNotification(id: string) {
    try {
      await httpFacade.notification.acceptNotification(id);

      const notificationIndex = this.data.findIndex(
        notification => notification.id === id,
      );

      if (notificationIndex !== -1) {
        this.data.splice(notificationIndex, 1);
      }
    } catch (error) {
      showHttpErrors(error);
    }
  }

  notify = (notification: NotificationModel) => {
    toast(
      ({ closeToast }) => (
        <Notification notification={notification} close={closeToast} />
      ),
      {
        ...this.config,
        toastId: notification.id,
      },
    );
  };

  closeAll() {
    toast.dismiss();
  }
}

export default new NotificationStore();
