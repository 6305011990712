import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isSameAs(compareName: string, msg?: string) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];
        const compareValue = obj[compareName];

        const isValid = value === compareValue;
        return isValid
          ? undefined
          : {
              message:
                msg ||
                getDisplayName(obj, name) +
                  ' should be the same as ' +
                  getDisplayName(obj, compareName),
              data: {
                name,
                value,
                compareValue,
                compareName,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
