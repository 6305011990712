import React, { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { action } from 'mobx';

import { Column } from './types';

interface Props {
  caption: Column;
  className?: string;
  onClick?: (caption) => void;
}

@observer
class GridCaption extends Component<Props> {
  @action.bound
  onClick = caption => () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(caption);
    }
  };

  render() {
    const { caption, className } = this.props;

    return (
      <div
        className={classNames('caption', className)}
        onClick={this.onClick(caption)}
      >
        {caption.header}
      </div>
    );
  }
}

export default GridCaption;
