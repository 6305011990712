import { action, computed, observable } from 'mobx';

import { sortByAccessor } from 'helpers/accessors';
import { showHttpErrors } from 'helpers/errors';
import httpFacade from 'http/httpFacade';
import { Invoice, InvoiceStatus } from 'types/entities';

class InvoicesStore {
  @computed
  get filteredInvoices() {
    return this.data.filter(
      invoice => invoice.status !== InvoiceStatus.accepted,
    );
  }

  @computed
  get sortedInvoices() {
    return this.filteredInvoices
      .slice()
      .sort(sortByAccessor({ desc: false, accessor: 'createdAt' }));
  }

  @observable pending: boolean = true;
  @observable private data: Invoice[] = [];

  @action
  async init() {
    await this.fetchInvoices();
  }

  @action.bound
  async fetchInvoices() {
    this.pending = true;

    try {
      const response = await httpFacade.invoices.fetchInvoicesForPeriod(
        new Date(1950, 0, 1),
        new Date(2049, 11, 31),
      );

      this.data = response.data;
    } catch (error) {
      showHttpErrors(error);
    }

    this.pending = false;
  }
}

export default InvoicesStore;
