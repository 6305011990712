import React, { Component, Suspense } from 'react';
import { RawIntlProvider } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';

import Spinner from 'components/Spinner/Spinner';
import Modals from 'components/Modals/Modals';
import BaseLayout from 'components/Layout/BaseLayout';
import InternalServerError from 'components/Errors/InternalServer/InternalServerError';
import RecoverPage from 'pages/Login/RecoverPage';
import Login from 'pages/Login/Login';

import { ROUTES } from 'routes/routes';
import RootStore from 'stores/RootStore';

import 'react-toastify/dist/ReactToastify.css';

import { getDefaultRouteByRole } from 'helpers/roles';

@observer
class App extends Component {
  async componentDidMount() {
    await RootStore.init();
  }

  render() {
    const { loading, config, localization, internalServerError } = RootStore;

    return loading || !config ? (
      <Spinner fullscreen />
    ) : (
      <RawIntlProvider value={localization.intl}>
        {internalServerError ? (
          <InternalServerError />
        ) : (
          <Suspense fallback={<Spinner fullscreen />}>
            <Switch>
              <Route
                path={[
                  ROUTES.bookingNew,
                  ROUTES.bookingEdit,
                  ROUTES.dashboard,
                  ROUTES.profile,
                  ROUTES.staffDashboard,
                  ROUTES.event,
                  ROUTES.menu,
                  ROUTES.manager,
                  ROUTES.cateringEdit,
                  ROUTES.users,
                  ROUTES.user,
                ]}
                component={BaseLayout}
              />
              <Route
                path={ROUTES.login}
                exact
                render={() =>
                  RootStore.user.roles.length ? (
                    <Redirect to={getDefaultRouteByRole()} />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route path={ROUTES.recover} component={RecoverPage} />
              <Redirect to={ROUTES.login} />
            </Switch>

            <Modals />
            <ToastContainer className="notifications" />
          </Suspense>
        )}
      </RawIntlProvider>
    );
  }
}

export default App;
