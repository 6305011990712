import http from '../http';

import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import { Arrangement } from 'types/entities';

type FetchArrangementsResponse = Arrangement[];

export const arrangementsApi = {
  fetchArrangements(minCapacity: number = 0) {
    return http.get<FetchArrangementsResponse>(appUrl(URLS.arrangements), {
      params: {
        minCapacity,
      },
    });
  },
};
