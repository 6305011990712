import React from 'react';
import { action, computed, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import { ROUTES } from 'routes/routes';
import { UserPriceGroup } from 'types/entities';
import AppRouter from 'stores/AppRouter';
import UserForm, { ICreateUserFormData } from 'stores/Forms/UserForm';
import { Option } from 'components/Form/Fields/Select/Select';

const hiddenRoles: string[] = ['SYSTEM', 'SERVICE_STAFF'];

export class CreateUserStore {
  @observable form: UserForm;
  @observable isLoading: boolean;
  @observable serverError: string = '';

  constructor({ id, userRole }: ICreateUserFormData) {
    this.form = new UserForm({ id, userRole });
  }

  get priceGroupsOptions(): Array<Option<UserPriceGroup>> {
    return Object.values(UserPriceGroup).map(value => ({
      label: <FormattedMessage id={`report.user.priceGroup.${value}`} />,
      value,
    }));
  }

  @computed
  get userRolesOptions(): Array<Option<string>> {
    return Object.values(this.form.roles).reduce((acc, role) => {
      if (!hiddenRoles.includes(role.name)) {
        acc.push({
          label: <FormattedMessage id={role.name} defaultMessage={role.name} />,
          value: role.id,
        });
      }

      return acc;
    }, [] as Array<Option<string>>);
  }

  @action.bound
  async init() {
    this.isLoading = true;
    try {
      await this.form.init();
    } catch (error) {
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  async createUser(onFulfilled?: () => void) {
    this.isLoading = true;

    try {
      if (this.form.id) {
        await httpFacade.users.updateUser({
          id: this.form.id,
          ...this.form.userData,
        });
      } else {
        await httpFacade.users.createUser(this.form.userData);
      }

      if (onFulfilled) {
        onFulfilled();
      }
    } catch (error) {
      if (
        error.response.status >= 400 &&
        error.response.status < 500 &&
        error.response.data?.errors
      ) {
        this.serverError = error.response.data.errors[0]?.message;
      }
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  async deleteUser() {
    try {
      this.isLoading = true;
      await httpFacade.users.deleteUser(this.form.id);

      AppRouter.push(ROUTES.users);
    } catch (error) {
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  resetErrors() {
    this.serverError = '';
  }
}
