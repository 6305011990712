export function groupBy<T extends {}, K extends keyof T>(
  arr: T[],
  propsName: K | ((v: T) => string),
): Record<string, T[]> {
  return arr.reduce((a, v) => {
    const groupName = (typeof propsName === 'function'
      ? propsName(v)
      : v[propsName]) as string;
    return {
      ...a,
      [groupName]: groupName in a ? [...a[groupName], v] : [v],
    };
  }, {});
}

export function toPairs<T>(data: Record<string, T>): Array<[string, T]> {
  return Object.keys(data).map(key => [key, data[key]]);
}

export function hasDuplicate(data: any[]): boolean {
  return data.some((v, i) => data.slice(i + 1).includes(v));
}
