import http from '../http';
import URLS from 'http/urls';

export const localizationApi = {
  fetchLocalization(locale) {
    return http.get<Record<string, string>>(URLS.locale, {
      replaceParams: { locale },
    });
  },
};
