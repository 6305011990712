import { DateLocalizer } from 'react-big-calendar';
import memoizeFormatConstructor from 'intl-format-cache';

// This code copied from react-big-calendar/src/localizers/globalize.js
// and removed all depending on other repositories

// TODO: need deep research

const dateRangeFormat = ({ start, end }, culture, local) =>
  local.format(start, { date: 'short' }, culture) +
  ' — ' +
  local.format(end, { date: 'short' }, culture);

const timeRangeFormat = ({ start, end }, culture, local) =>
  local.format(start, { time: 'short' }, culture) +
  ' — ' +
  local.format(end, { time: 'short' }, culture);

const timeRangeStartFormat = ({ start }, culture, local) =>
  local.format(start, { time: 'short' }, culture) + ' — ';

const timeRangeEndFormat = ({ end }, culture, local) =>
  ' — ' + local.format(end, { time: 'short' }, culture);

const weekRangeFormat = ({ start, end }, culture, local) => 'not implemented';
// local.format(start, 'MMM dd', culture) +
//   ' — ' +
//   local.format(end, dates.eq(start, end, 'month') ? 'dd' : 'MMM dd', culture);

export let formats = {
  dateFormat: 'dd',
  dayFormat: 'eee dd/MM',
  weekdayFormat: 'eee',

  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  timeGutterFormat: { time: 'short' },

  monthHeaderFormat: 'MMMM yyyy',
  dayHeaderFormat: 'eeee MMM dd',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,

  agendaDateFormat: 'eee MMM dd',
  agendaTimeFormat: { time: 'short' },
  agendaTimeRangeFormat: timeRangeFormat,
};

const getDateTimeFormat = memoizeFormatConstructor(Intl.DateTimeFormat);

function localizer() {
  return new DateLocalizer({
    formats,
    firstOfWeek() {
      return 0;
    },

    format(value, format, culture) {
      return getDateTimeFormat(culture).format(value);
    },
  });
}

export default localizer;
