import { arrangementsApi as arrangements } from './Api/arrangements';
import { menuApi as menu } from './Api/menu';
import { notificationApi as notification } from './Api/notification';
import { localizationApi as localization } from './Api/localization';
import { eventsApi as events } from './Api/events';
import { invoicesApi as invoices } from './Api/invoices';
import { roomsApi as rooms } from './Api/rooms';
import { rootApi as root } from './Api/root/root';
import { statisticsApi as statistics } from './Api/statistics';
import { userApi as user } from './Api/User/user';
import { reportApi as report } from './Api/report';
import { usersApi as users } from './Api/users';

const httpFacade = {
  report,
  arrangements,
  events,
  invoices,
  localization,
  menu,
  notification,
  root,
  rooms,
  statistics,
  user,
  users,
};

export default httpFacade;
