import http from '../http';

import { appUrl } from 'http/helpers';
import URLS from 'http/urls';

interface FetchStatisticsResponse {
  thisWeekBookings: number;
  nextWeekBookings: number;
  laterBookings: number;
  openedInvoices: number;
  acceptedInvoices: number;
  declinedInvoices: number;
}

export const statisticsApi = {
  fetchCatererStatistics() {
    return http.get<FetchStatisticsResponse>(appUrl(URLS.statisticsCaterer));
  },

  fetchSecretaryStatistics() {
    return http.get<FetchStatisticsResponse>(appUrl(URLS.statisticsSecretary));
  },
};
