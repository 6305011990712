import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ValidationResult } from 'stores/BaseForm/types';

interface FormControlErrorsProps {
  error: ValidationResult;
}

const FormControlError = ({ error }: FormControlErrorsProps) => {
  return (
    <FormattedMessage
      id={error.message}
      defaultMessage={error.message}
      key={error.message}
      values={{
        ...error.data,
        name: (
          <FormattedMessage
            id={error.data.name}
            defaultMessage={error.data.name}
          />
        ),
      }}
    />
  );
};

export default FormControlError;
