import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';

import style from './Counter.module.scss';

import noop from 'helpers/noop';

interface Props {
  value: number;
  reduce?: () => void;
  increase?: () => void;
  edit?: (event) => void;
  className?: string;
  min?: number;
}

const NUMBER_PATTERN = /[0-9]/;
const MIN_VALUE = 1;

@observer
class Counter extends Component<Props> {
  onKeyPress = event => {
    if (!NUMBER_PATTERN.test(event.key)) {
      event.preventDefault();
    }
  };

  onChange = event => {
    const { edit = noop } = this.props;

    edit(event.target.value);
  };

  onBlur = event => {
    const { edit = noop } = this.props;
    const { value } = event.target;

    if (value === '' || value < MIN_VALUE || !Number(value)) {
      edit(MIN_VALUE);
    } else {
      edit(value.replace(/^0+/g, ''));
    }
  };

  render() {
    const {
      value,
      edit,
      className,
      min = MIN_VALUE,
      reduce,
      increase,
    } = this.props;

    const readOnly = !Boolean(edit);

    return (
      <div className={classNames('row', className)}>
        <Button className={style.btn} onClick={reduce} disabled={value <= min}>
          <Icon type="minus" />
        </Button>

        <input
          id="editable-value"
          type="text"
          size={3}
          maxLength={3}
          value={value}
          readOnly={readOnly}
          className={classNames(style.value, {
            [style.value__readOnly]: readOnly,
          })}
          onKeyPress={this.onKeyPress}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />

        <Button className={style.btn} onClick={increase}>
          <Icon type="plus" />
        </Button>
      </div>
    );
  }
}

export default Counter;
