import { action, observable, computed } from 'mobx';

import { dateAdd } from 'helpers/datetime';

import httpFacade from 'http/httpFacade';

import { Event } from 'types/entities';

export enum EventTime {
  upcoming = 'UPCOMING',
  ongoing = 'ONGOING',
  finished = 'FINISHED',
}

class ManagerDashboardStore {
  @observable pending = false;
  @observable eventTime: EventTime = EventTime.upcoming;

  @observable private _data: Event[] = [];
  @observable private _events: any = {
    upcoming: [],
    ongoing: [],
    finished: [],
  };

  @computed get data() {
    switch (this.eventTime) {
      case EventTime.upcoming:
        return this._events.upcoming;
      case EventTime.ongoing:
        return this._events.ongoing;
      case EventTime.finished:
        return this._events.finished;
      default:
        return this._data;
    }
  }

  @computed get upcomingEventsAmount() {
    return this._events.upcoming.length;
  }

  @computed get ongoingEventsAmount() {
    return this._events.ongoing.length;
  }

  @computed get finishedEventsAmount() {
    return this._events.finished.length;
  }

  async init(date: Date) {
    this.fetchEvents(date);
  }

  @action.bound
  async fetchEvents(date: Date) {
    this.pending = true;

    try {
      const { data } = await httpFacade.events.fetchEvents(date);

      this._data = data.filter(event => !event.duplicated);
      this.groupByTime();
    } catch (error) {
      // TODO: message
    }

    this.pending = false;
  }

  @action
  private groupByTime() {
    const now = new Date();

    return this._data.reduce((result, event) => {
      const period = dateAdd(event.start, event.duration, 'minute');

      if (now >= event.start && now <= period) {
        result.ongoing.push(event);
      } else if (now < period) {
        result.upcoming.push(event);
      } else if (now > period) {
        result.finished.push(event);
      }

      return result;
    }, this._events);
  }
}

export default ManagerDashboardStore;
