import { action, observable } from 'mobx';

import RootStore from '../RootStore';
import { BaseFormModel, isRequired, displayName } from '../BaseForm';

class LoginForm extends BaseFormModel {
  @observable pending = false;

  @observable
  @displayName('EMAIL')
  @isRequired()
  email = '';

  @observable
  @displayName('PASSWORD')
  @isRequired()
  password = '';

  @observable message: string;

  @action.bound
  async submit(event) {
    event.preventDefault();

    this.pending = true;

    if (this.validate()) {
      try {
        const requestData = {
          username: this.email,
          password: this.password,
        };

        await RootStore.user.logIn(requestData);
      } catch (error) {
        this.email = '';
        this.password = '';
        this.message = 'page.login.incorrect';
      }
    }

    this.pending = false;
  }
}

export default LoginForm;
