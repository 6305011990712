import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

function buildNumberValueValidator(
  condition: (value: number, limit: number) => boolean,
  defaultMsg: string,
) {
  return (limit: number, msg?: string) => {
    return (target: any, fieldName: string) => {
      const validation = {
        fieldName,
        validateFunction: (obj: any) => {
          const name = getDisplayName(obj, fieldName);
          const value = obj[fieldName];
          const numberValue = Number(value);

          const isValid =
            !value || (!isNaN(numberValue) && condition(numberValue, limit));
          return isValid
            ? undefined
            : {
                message: msg || defaultMsg,
                data: {
                  name,
                  value,
                  limit,
                },
              };
        },
      };

      addValidation(target, fieldName, validation);
    };
  };
}

export const isNumberLess = buildNumberValueValidator(
  (value, limit) => value < limit,
  'validator.message.isNumberLess',
);
export const isNumberLessOrEqual = buildNumberValueValidator(
  (value, limit) => value <= limit,
  'validator.message.isNumberLessOrEqual',
);
export const isNumberMore = buildNumberValueValidator(
  (value, limit) => value > limit,
  'validator.message.isNumberMore',
);
export const isNumberMoreOrEqual = buildNumberValueValidator(
  (value, limit) => value >= limit,
  'validator.message.isNumberMoreOrEqual',
);
