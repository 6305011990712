import React, { Component } from 'react';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './FormControl.module.scss';

import { Omit } from 'helpers/types';

import { BaseFormModel } from 'stores/BaseForm/BaseFormModel';

import FormControlError from './FormControlError';

type Validation = 'change' | 'blur';

interface IRenderProps<T> {
  className?: string;
  name: string;
  value: T;
  onChange: (value: T) => void;
  onBlur: (value: T) => void;
  disabled: boolean;
  invalid: boolean;
}

interface Props<T> {
  className?: string;
  name: string;
  form: BaseFormModel;
  render: (props: IRenderProps<T>) => React.ReactElement<any>;
  disabled?: boolean;
  showError?: boolean;
  validateOn?: Validation;
}

const defaultValidationOn: Validation = 'change';

@observer
class FormControl<T = any> extends Component<Props<T>> {
  @observable isFieldInvalid = false;

  @computed
  get errors() {
    const { form, name } = this.props;

    return this.isFieldInvalid || form.validated ? form.errorFor(name) : [];
  }

  onChange = value => {
    this.props.form.setValue(this.props.name, value);
    if (this.props.validateOn === 'change') {
      this.validateField();
    }
  };

  onBlur = () => {
    if (this.props.validateOn === 'blur') {
      this.validateField();
    }
  };

  validateField = () => {
    const { name, form } = this.props;

    this.isFieldInvalid = !form.isValid(name);
  };

  render() {
    const {
      className,
      name,
      form,
      render,
      showError = true,
      disabled = false,
    } = this.props;
    const value = form[name];
    const error = this.errors[0];

    const renderProps: IRenderProps<T> = {
      value,
      name,
      onChange: this.onChange,
      onBlur: this.onBlur,
      invalid: Boolean(error),
      disabled,
    };

    return (
      <div className={classNames(style.control, className)}>
        {disabled && <div className={style.mask} />}
        <label className={style.label} htmlFor={name}>
          <FormattedMessage id={form.displayName(name)} defaultMessage={name} />
        </label>

        {render(renderProps)}

        {showError && error?.message && (
          <div className={style.errorWrapper}>
            <FormControlError error={error} />
          </div>
        )}
      </div>
    );
  }
}

const bindFormControl = (form: BaseFormModel) => <T extends unknown = any>(
  props: Omit<Props<T>, 'form'>,
) => <FormControl form={form} validateOn={defaultValidationOn} {...props} />;

export { FormControl, bindFormControl };
