import React from 'react';
import classNames from 'classnames';
import SliderSlick from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import style from './Slider.module.scss';

import Icon from 'components/Icon/Icon';

interface Props {
  children: React.ReactNode;
  slidesToShow?: number;
  slidesToScroll?: number;
  responsive?: Array<{
    breakpoint: number;
    settings: {
      slidesToShow?: number;
      slidesToScroll?: number;
      initialSlide?: number;
    };
  }>;
  infinite?: boolean;
  className?: string;
  rows?: number;
  initialSlide?: number;
}

const Slider = ({ className, ...props }: Props) => {
  return (
    <SliderSlick
      className={classNames(className, style.slider)}
      infinite={false}
      speed={500}
      prevArrow={<Icon type="arrowLeft" />}
      nextArrow={<Icon type="arrowRight" />}
      {...props}
    />
  );
};

export default Slider;
