import { ConfigParams, FetchDefaultConfigResponse } from './types';

export function convertParamsToObject(
  params: ConfigParams[],
): Partial<FetchDefaultConfigResponse> {
  return params.reduce((acc, configResponse) => {
    const paramKey = configResponse.paramKey.replace('ui.', '');
    const paramValue = paramKey.includes('enabled')
      ? JSON.parse(configResponse.paramValue)
      : configResponse.paramValue;

    const keys = paramKey.split('.');
    let cursor = acc;

    keys.forEach((key, index) => {
      if (!cursor[key]) {
        cursor[key] = index === keys.length - 1 ? paramValue : {};
      }
      cursor = cursor[key];
    });

    return acc;
  }, {});
}
