import http from 'http/http';
import URLS from 'http/urls';
import { appUrl } from 'http/helpers';

import SETTINGS from 'settings';

import { ConfigParams, FetchDefaultConfigResponse } from './types';

export const rootApi = {
  fetchConfig() {
    return http.get<ConfigParams[]>(appUrl(URLS.config));
  },

  fetchDefaultConfig() {
    return http.get<FetchDefaultConfigResponse>(
      `${SETTINGS.publicURL}/config.json`,
    );
  },
};
