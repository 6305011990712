export enum NotificationPriority {
  low = 'LOW',
  medium = 'MEDIUM',
  high = 'HIGH',
}

export enum NotificationStatus {
  new = 'NEW',
  accepted = 'ACCEPTED',
}

export enum NotificationType {
  event = 'EVENT',
  invoice = 'INVOICE',
}

export interface NotificationModel {
  id: string;
  message: string;
  params: Record<string, any>;
  priority: NotificationPriority;
  status: NotificationStatus;
  targetId: string;
  targetUser: string;
  type: NotificationType;
}
