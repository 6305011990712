import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import RootStore from 'stores/RootStore';

import { ROUTES } from './routes';
import { Roles } from '../helpers/roles';

const { user } = RootStore;

interface Props {
  component: any;
  roles: Roles[];

  [name: string]: any;
}

@observer
class PrivateRouter extends Component<Props> {
  render() {
    const {
      component: ActiveComponent,
      roles,
      computedMatch,
      ...rest
    } = this.props;
    const { id } = computedMatch.params;
    const access =
      user.authenticated &&
      roles.some(role => RootStore.user.roles.includes(role));

    return (
      <Route
        {...rest}
        render={props =>
          access ? (
            <ActiveComponent {...props} key={id} />
          ) : (
            <Redirect to={ROUTES.login} />
          )
        }
      />
    );
  }
}

export default PrivateRouter;
