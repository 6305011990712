import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './Reporting.module.scss';

import ReportStore from 'stores/ReportStore';

import Select from 'components/Form/Fields/Select/Select';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import Grid from 'components/Grid/Grid';
import ReportItem from 'components/Grid/Items/ReportItem';
import InnerCalendar from 'components/InnerCalendar/InnerCalendar';
import { Types } from 'components/Grid/types';
import { DEFAULT_CATEGORIES_SORTING } from 'pages/config';

import { BookingType, Category, UserPriceGroup } from 'types/entities';

export const gridConfig: Types<Category> = {
  columns: [
    {
      header: <FormattedMessage id="title.group" />,
      id: 'title',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.user" />,
      id: 'priceGroup',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.pax" />,
      id: 'amount',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.netto" />,
      id: 'netto',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.brutto" />,
      id: 'brutto',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.purchase" />,
      id: 'purchase',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.profit" />,
      id: 'profit',
      sortable: true,
    },
  ],
  item: ReportItem,
};

@observer
class Reporting extends Component {
  report = new ReportStore();

  async componentDidMount() {
    await this.report.fetchData();
  }

  preparingPDF = (print?: boolean) => async () => {
    await this.report.fetchPDF(print);
  };

  get userPriceGroups() {
    return [
      ...Object.values(UserPriceGroup).map(value => ({
        label: <FormattedMessage id={`report.user.priceGroup.${value}`} />,
        value,
      })),
      {
        label: <FormattedMessage id="report.user.priceGroup.ANY" />,
        value: '',
      },
    ];
  }

  get bookingTypes() {
    return Object.values(BookingType).map(value => ({
      label: <FormattedMessage id={`report.booking.type.${value}`} />,
      value: value === BookingType.ANY ? '' : value,
    }));
  }

  render() {
    const { categories, data, isLoading } = this.report;

    return (
      <div className={style.content}>
        <section className={style.wrapper__description}>
          <div className={style.controls}>
            <div className={style.controls__pdf}>
              <Button
                className={style.btn__pdf}
                onClick={this.preparingPDF(true)}
              >
                <Icon className={style.icon} type="printer" />
              </Button>

              <Button className={style.btn__pdf} onClick={this.preparingPDF()}>
                <Icon type="pdf" className={style.icon} />
              </Button>
            </div>

            <Button styleType="accept" shadow onClick={this.report.fetchData}>
              <FormattedMessage id="apply" />
            </Button>
          </div>

          <div className={style.wrapper__calendar}>
            <InnerCalendar
              className={style.calendar}
              selectRange
              value={this.report.period}
              onClickDay={this.report.onDateChange}
            />
          </div>

          <div className={style.wrapper__select}>
            <div className={style.select__title}>
              <FormattedMessage id="person" />
            </div>

            <Select
              name="kostenstelleNr"
              value={this.report.selectedFilter?.kostenstelleNr}
              options={this.report.customNumbers}
              classNamePrefix="select"
              className={style.select}
              onChange={this.report.changeFilter}
            />
          </div>

          <div className={style.wrapper__select}>
            <div className={style.select__title}>
              <FormattedMessage id="report.user.type.filter" />
            </div>

            <Select
              name="userType"
              value={this.report.selectedFilter?.userType}
              options={this.userPriceGroups}
              classNamePrefix="select"
              className={style.select}
              onChange={this.report.changeFilter}
            />
          </div>

          <div className={style.wrapper__select_last}>
            <div className={style.select__title}>
              <FormattedMessage id="report.booking.type.filter" />
            </div>

            <Select
              name="bookingType"
              value={this.report.selectedFilter?.bookingType}
              options={this.bookingTypes}
              classNamePrefix="select"
              className={style.select}
              onChange={this.report.changeFilter}
            />
          </div>

          <div className={style.wrapper__calculations}>
            <div className={style.calculation}>
              <span className={style.calculation__title}>
                <FormattedMessage id="text.booking.amount" />
              </span>

              <span>{data.eventsCount}</span>
            </div>

            <div className={style.calculation}>
              <span className={style.calculation__title}>
                <FormattedMessage id="text.total.netto" />
              </span>

              <FormattedCurrency value={data.netto} />
            </div>

            <div className={style.calculation}>
              <span className={style.calculation__title}>
                <FormattedMessage id="text.total.brutto" />
              </span>

              <FormattedCurrency value={data.brutto} />
            </div>

            <div className={style.calculation}>
              <span className={style.calculation__title}>
                <FormattedMessage id="text.total.profit" />
              </span>

              <FormattedCurrency value={data.profit} />
            </div>
          </div>
        </section>

        <section className={style.wrapper__grid}>
          <Grid
            loading={isLoading}
            data={categories}
            config={gridConfig}
            className={style.grid}
            onSort={sortConfig => (this.report.sortConfig = sortConfig)}
            defaultSorted={DEFAULT_CATEGORIES_SORTING}
          />
        </section>
      </div>
    );
  }
}

export default Reporting;
