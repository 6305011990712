import { observable, computed, toJS, action } from 'mobx';

import { Room, Arrangement, RoomPrice, PriceTier } from 'types/entities';

const DEFAULT_CLEANING = 15;
const DEFAULT_PREPARATION = 15;

export type RoomDataType = 'id' | 'title' | 'cleaning' | 'preparation';

class RoomModel implements Room {
  @observable id = '';
  @observable title = '';
  @observable description = '';
  @observable arrangements: Arrangement[] = [];
  @observable.shallow images: string[] = [];
  @observable.shallow equipments: string[] = [];
  @observable priceTiers: RoomPrice | null = null;
  @observable preparation = DEFAULT_PREPARATION;
  @observable cleaning = DEFAULT_CLEANING;

  @computed get capacity() {
    return Math.max(
      0,
      ...this.arrangements.map(arrangement => arrangement.capacity),
    );
  }

  @computed get tiers() {
    return this.priceTiers?.tiers || [];
  }

  constructor(data?: Room) {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.description = data.description;
      this.arrangements = data.arrangements;
      this.images = data.images;
      this.equipments = data.equipments;
      this.priceTiers = data.priceTiers
        ? { tiers: this.sortTiers(data.priceTiers) }
        : null;
      this.preparation = data.preparation;
      this.cleaning = data.cleaning;
    }
  }

  sortTiers(priceTiers: RoomPrice) {
    const tiers = priceTiers.tiers.sort((a, b) =>
      a.maxDurationMins > b.maxDurationMins ? 1 : -1,
    );
    if (tiers[0]?.maxDurationMins === null) {
      // high tier has maxDurationMins=null
      const tier = tiers.shift();
      tiers.push(tier as PriceTier);
    }
    return tiers;
  }

  price(duration) {
    const tiers = toJS(this.tiers);
    let price: number = tiers[0]?.price || 0;
    for (let i = 0; i < tiers.length; i++) {
      if (i === tiers.length - 1) {
        price = tiers[i].price;
      } else if (duration <= tiers[i].maxDurationMins) {
        price = tiers[i].price;
        break;
      }
    }

    return price;
  }

  @action.bound
  update(data: Pick<Room, RoomDataType>) {
    this.id = data.id;
    this.title = data.title;
    this.preparation = data.preparation;
    this.cleaning = data.cleaning;
  }
}

export default RoomModel;
