import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import BookingStore from 'stores/Booking/BookingStore';

import Spinner from 'components/Spinner/Spinner';
import Button from 'components/Button/Button';
import AlertModal from 'components/Modals/AlertModal/AlertModal';
import Switch from 'components/Switch/Switch';
import Planner from 'components/Planner/Planner';
import Filter from './Filter';
import Navigation from '../Navigation/Navigation';

import style from './Planner.module.scss';
import { getRole, Roles } from 'helpers/roles';

interface Props {
  store: BookingStore;
}

@observer
class PlannerStep extends Component<Props> {
  render() {
    const { store } = this.props;
    const { event, stepper, selectRoom } = store;
    const isNexStepDisabled =
      !event.reservations.length ||
      (getRole(Roles.MASTER_BOOKING) && !event.owner.id);

    return (
      <div className={style.page}>
        <section className={style.navigation}>
          <div className={style.navigationRow}>
            <Navigation store={stepper} />
          </div>

          <div className={style.filterSection}>
            <Filter bookingStore={store} />

            <label className={style.onlyAvailableLabel}>
              <FormattedMessage id="event.part.filter.onlyAvailable" />

              <Switch
                value={selectRoom.onlyAvailable}
                onChange={v => (selectRoom.onlyAvailable = v)}
              />
            </label>
          </div>

          <div
            className={classNames(
              style.summary__wrapper,
              !isNexStepDisabled && style.visible,
            )}
          >
            <div className={style.summary}>
              <div className={style.summary__info}>
                <div className={style.summary__title}>
                  <FormattedMessage id="booking.orderSetup.summary" />
                </div>
                <div className={style.summary__total}>
                  <FormattedMessage
                    id="booking.orderSetup.roomsAmount"
                    values={{
                      amount: event.reservations.length,
                    }}
                  />
                </div>
              </div>

              <Button
                styleType="accept"
                className={style.btn}
                disabled={isNexStepDisabled}
                onClick={stepper.nextStep}
              >
                <FormattedMessage id="button.continue" />
              </Button>
            </div>
          </div>
        </section>

        <section className={style.content}>
          {selectRoom.pending && <Spinner contain />}
          <Planner
            date={selectRoom.date}
            events={selectRoom.allEvents}
            resource={selectRoom.rooms}
            duration={selectRoom.duration}
            onSelect={selectRoom.selectRoom}
            maxCleaning={store.maxCleaningDuration}
            maxPreparation={store.maxPreparationDuration}
          />
        </section>

        {selectRoom.showTermError && (
          <AlertModal
            message="error.possibleTerm"
            onClose={() => (selectRoom.showTermError = false)}
          />
        )}

        {selectRoom.showAvailableError && (
          <AlertModal
            message="error.notAvailableRoom"
            onClose={() => (selectRoom.showAvailableError = false)}
          />
        )}
      </div>
    );
  }
}

export default PlannerStep;
