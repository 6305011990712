export enum FILTERS {
  GRID__EVENTS__PERIOD = 'GRID__EVENTS__PERIOD',
  GRID__EVENTS__STATUS = 'GRID__EVENTS__STATUS',
  GRID__INVOICE__STATUS = 'GRID__INVOICE__STATUS',
  GRID__INVOICE__PERIOD = 'GRID__INVOICE__PERIOD',
}

class FiltersStore {
  static saveFilter(type: FILTERS, value) {
    sessionStorage.setItem(type, value);

    return FiltersStore;
  }

  static getFilter(type: FILTERS) {
    return sessionStorage.getItem(type);
  }

  static removeFilter(type: FILTERS) {
    sessionStorage.removeItem(type);

    return FiltersStore;
  }

  static clearFilters() {
    sessionStorage.clear();

    return FiltersStore;
  }
}

export default FiltersStore;
