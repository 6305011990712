import { action, observable } from 'mobx';

import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { MenuSet } from 'types/entities';
import RootStore from 'stores/RootStore';

export class FavoriteMenuModel {
  @observable packages: MenuSet[] = [];

  @action.bound
  async fetchFoodPackages() {
    try {
      const { user } = RootStore;
      const response = await httpFacade.menu.fetchMenu(user.priceGroup);

      this.packages = response.data.filter(menuItem => menuItem.favorite);
    } catch (error) {
      // TODO: error
      Log.error(error);
    }
  }
}

export default FavoriteMenuModel;
