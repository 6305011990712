import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

import { Category } from 'types/entities';

interface Props {
  item: Category;
  className?: string;
  onClick?: () => void;
}

const ReportItem = (props: Props) => {
  const { item } = props;

  return (
    <div className="grid-row item item__report">
      <div className="column">
        <span>{item.title}</span>
      </div>
      <div className="column">
        <FormattedMessage id={`report.user.priceGroup.${item.priceGroup}`} />
      </div>
      <div className="column">{item.amount}</div>

      <div className="column">
        <FormattedCurrency value={item.netto} />
      </div>

      <div className="column">
        <FormattedCurrency value={item.brutto} />
      </div>

      <div className="column">
        <FormattedCurrency value={item.purchase} />
      </div>

      <div className="column">
        <FormattedCurrency value={item.profit} />
      </div>
    </div>
  );
};

export default ReportItem;
