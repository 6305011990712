import React from 'react';
import RcSwitch from 'rc-switch';
import classNames from 'classnames';

import style from './Switch.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
}

function Switch(props: Props) {
  const { className, value, ...otherProps } = props;

  return (
    <RcSwitch
      checked={value}
      className={classNames(style.switch, className)}
      {...otherProps}
    />
  );
}

export default Switch;
