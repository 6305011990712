import Log from './log';

import { SortConfig } from 'types/entities';

export function getValueByAccessor(obj: any, accessor: string) {
  const path = accessor.split('.');

  return path.reduce((value, property) => value[property], obj);
}

export function sortByAccessor(
  sort: SortConfig | undefined,
  caseSensitive = false,
) {
  return (a, b) => {
    if (!sort) {
      return 0;
    }

    try {
      let valueA =
        typeof sort.accessor === 'function'
          ? sort.accessor(a)
          : getValueByAccessor(a, sort.accessor);
      let valueB =
        typeof sort.accessor === 'function'
          ? sort.accessor(b)
          : getValueByAccessor(b, sort.accessor);

      if (!caseSensitive && typeof valueA === 'string') {
        valueA = valueA?.toLowerCase();
        valueB = valueB?.toLowerCase();
      }

      return (
        (valueA < valueB ? -1 : valueA > valueB ? 1 : 0) * (sort.desc ? -1 : 1)
      );
    } catch (error) {
      Log.error(error);

      return 0;
    }
  };
}
