import React from 'react';
import classNames from 'classnames';

import { Omit } from 'helpers/types';

import style from './CheckboxField.module.scss';

interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (v: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  invalid?: boolean;
}

const CheckboxField = (props: CheckboxProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange && props.onChange(event.target.checked, event);

  const { checked, className, children, type, invalid, ...rest } = props;

  return (
    <label className={classNames(style.checkbox, className)}>
      <input
        {...rest}
        checked={checked}
        type="checkbox"
        className={style.model}
        onChange={onChange}
      />

      <svg
        viewBox="0 0 24 24"
        className={classNames(style.svg, style.svg__fill, {
          [style.invalid]: invalid,
        })}
      >
        <polyline points="20 6 9 17 4 12" className={style.tip} />
      </svg>

      {children}
    </label>
  );
};

export default CheckboxField;
