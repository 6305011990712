import React from 'react';
import { action, computed, observable, reaction } from 'mobx';
import { FormattedMessage } from 'react-intl';

import CommentModal from 'components/Modals/CommentModal/CommentModal';

import { sortByAccessor } from 'helpers/accessors';
import { dateAdd, dateEndOf, dateStartOf } from 'helpers/datetime';
import { showHttpErrors } from 'helpers/errors';
import {
  filterInvoicesByStatus,
  FilterPeriods,
  InvoiceStatuses,
} from 'helpers/filters';
import Log from 'helpers/log';
import { getRole, Roles } from 'helpers/roles';

import httpFacade from 'http/httpFacade';

import StorageStore, { FILTERS } from 'stores/FiltersStore';
import ModalStore from 'stores/ModalStore';

import { Invoice, SortConfig } from 'types/entities';
import RootStore from './RootStore';
import { downloadBlob, printBlob } from '../helpers/pdf';

export const invoiceConfigColumnStartAccessor = (invoice: Invoice) =>
  dateAdd(invoice.event.start, invoice.event.duration, 'minute');

class InvoiceGridStore {
  static periodOptions = [
    {
      value: FilterPeriods.thisMonth,
      label: <FormattedMessage id="options.thisMonth" />,
    },
    {
      value: FilterPeriods.pastMonths,
      label: <FormattedMessage id="options.past" />,
    },
  ];

  static get statusOptions() {
    const isManager = getRole(Roles.CATERER_MANAGER);

    const options = [
      {
        value: InvoiceStatuses.all,
        label: <FormattedMessage id="options.all" />,
      },
      {
        value: InvoiceStatuses.inApproval,
        label: <FormattedMessage id="options.inApproval" />,
      },
      {
        value: InvoiceStatuses.approved,
        label: (
          <FormattedMessage
            id={isManager ? 'options.approved2' : 'options.approved'}
          />
        ),
      },
      {
        value: InvoiceStatuses.declined,
        label: <FormattedMessage id="options.declined" />,
      },
    ];

    if (getRole(Roles.CATERER_MANAGER)) {
      options.splice(1, 0, {
        value: InvoiceStatuses.draft,
        label: <FormattedMessage id="options.draft" />,
      });
    }

    return options;
  }

  @observable selectedInvoiceID?: string;
  @observable period: FilterPeriods | string = FilterPeriods.thisMonth;
  @observable status: InvoiceStatuses | string = InvoiceStatuses.all;
  @observable pending: boolean = true;
  @observable preparingPDF: boolean = false;
  @observable sortConfig?: SortConfig = {
    accessor: invoiceConfigColumnStartAccessor,
    desc: true,
  };
  @observable pdfBlobURL: any;
  @observable pdfBlob: any;

  @observable private _data: Invoice[] = [];

  constructor(id?) {
    this.selectedInvoiceID = id;

    if (!this.selectedInvoiceID) {
      const period = StorageStore.getFilter(FILTERS.GRID__INVOICE__PERIOD);
      const status = StorageStore.getFilter(FILTERS.GRID__INVOICE__STATUS);

      if (period) {
        this.period = period;
      }

      if (status) {
        this.status = status;
      }
    }

    reaction(
      () => this.period,
      () => {
        this.fetchInvoices();
        this.shakeActiveItem();
      },
    );

    reaction(
      () => this.status,
      () => this.shakeActiveItem(),
    );

    reaction(
      () => this.activeItem,
      item => {
        if (item) {
          this.fetchPDF(item.event.id);
        } else {
          this.pdfBlobURL = undefined;
        }
      },
    );
  }

  @computed get activeItem(): Invoice | undefined {
    return this.data.find(item => item.id === this.selectedInvoiceID);
  }

  @computed
  get startDate() {
    const now = new Date();

    switch (this.period) {
      case FilterPeriods.thisMonth:
        return dateStartOf(now, 'month');
      case FilterPeriods.pastMonths:
        return new Date(1950, 0, 1);
      default:
        return now;
    }
  }

  @computed
  get endDate() {
    const now = new Date();

    switch (this.period) {
      case FilterPeriods.thisMonth:
        return dateEndOf(now, 'month');
      case FilterPeriods.pastMonths:
        return dateAdd(dateStartOf(now, 'month'), -1, 'second');
      default:
        return new Date(2050, 0, 1);
    }
  }

  @action
  shakeActiveItem() {
    if (!this.activeItem && this.data[0]) {
      this.selectedInvoiceID = this.data[0].id;
    }
  }

  @computed
  get data() {
    return this._data
      .filter(invoice => filterInvoicesByStatus(this.status, invoice))
      .sort(sortByAccessor(this.sortConfig));
  }

  @action
  async init() {
    await this.fetchInvoices();
  }

  @action.bound
  async accept(invoice: Invoice) {
    this.pending = true;

    try {
      await httpFacade.invoices.acceptInvoice(invoice.id, invoice.version || 0);

      this.fetchInvoices();
    } catch (error) {
      showHttpErrors(error);

      this.pending = false;
    }
  }

  @action.bound
  async approve(invoice: Invoice) {
    this.pending = true;

    try {
      await httpFacade.invoices.approveInvoice(
        invoice.id,
        invoice.version || 0,
      );

      this.fetchInvoices();
    } catch (error) {
      showHttpErrors(error);

      this.pending = false;
    }
  }

  @action.bound
  async decline(invoice: Invoice) {
    try {
      const { payload } = await ModalStore.showModal(CommentModal, {
        inputLabel: 'modal.declineInvoice.input.label',
        inputPlaceholder: 'modal.declineInvoice.input.placeholder',
        okBtnText: 'button.reject',
        cancelBtnText: 'button.abort',
      });

      this.pending = true;

      try {
        await httpFacade.invoices.declineInvoice(
          invoice.id,
          payload,
          invoice.version || 0,
        );

        this.fetchInvoices();
      } catch (error) {
        showHttpErrors(error);

        this.pending = false;
      }
    } catch (error) {
      // nothing, so good
    }
  }

  @action.bound
  async fetchInvoices() {
    this.pending = true;

    try {
      const response = await httpFacade.invoices.fetchInvoicesForPeriod(
        this.startDate,
        this.endDate,
      );

      this._data = response.data;

      this.shakeActiveItem();
    } catch (error) {
      showHttpErrors(error);
    }

    this.pending = false;
  }

  @action.bound
  async fetchPDF(eventID: string) {
    try {
      const { data } = await httpFacade.invoices.fetchInvoiceReport(eventID);

      this.pdfBlobURL = URL.createObjectURL(data);
      this.pdfBlob = new Blob([data], {
        type: 'application/pdf',
      });
    } catch (error) {
      showHttpErrors(error);
    }
  }

  @action.bound
  getPDF(print?: boolean) {
    if (!this.activeItem) {
      return;
    }

    this.preparingPDF = true;

    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.invoice',
        {
          number: this.activeItem.number,
        },
      );
      const anchor = document.createElement('a');
      const pdf = {
        fileName,
        pdfBlob: this.pdfBlob,
        pdfBlobURL: this.pdfBlobURL,
      };

      document.body.appendChild(anchor);

      if (print) {
        printBlob(pdf);
      } else {
        downloadBlob(pdf);
      }
    } catch (error) {
      Log.error(error);
    }

    this.preparingPDF = false;
  }
}

export default InvoiceGridStore;
