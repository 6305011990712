import React from 'react';

import { Omit, GetComponentProps } from 'helpers/types';

import TextField from '../TextField/TextField';

const PasswordField = (props: Omit<GetComponentProps<TextField>, 'type'>) => (
  <TextField type="password" {...props} />
);

export default PasswordField;
