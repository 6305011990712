import React, { Component } from 'react';
import classNames from 'classnames';

import style from './NumberField.module.scss';

import Icon from 'components/Icon/Icon';
import { Omit } from 'helpers/types';

import TextField from '../TextField/TextField';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  invalid?: boolean;
  onChange?: (v: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  notEmpty?: boolean;
}

interface State {
  showValue: string | number;
  focused: boolean;
}

class NumberField extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (
      props.notEmpty &&
      state.showValue !== '' &&
      props.value !== state.showValue
    ) {
      return {
        showValue: props.value,
      };
    }

    return null;
  }

  state = {
    showValue: 0,
    focused: false,
  };

  onChange = (value: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.notEmpty) {
      this.setState({
        showValue: value,
      });
    }

    if (value !== '' || !this.props.notEmpty) {
      if (this.props.onChange) {
        if (
          (this.props.min !== undefined && value < this.props.min) ||
          (this.props.max !== undefined && value > this.props.max)
        ) {
          return;
        }
        this.props.onChange(value, event);
      }
    }
  };

  onIncrement = (step: number) => (
    event: React.ChangeEvent<HTMLDivElement>,
  ) => {
    this.onChange(String(+this.props.value + step), event as any);
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.notEmpty) {
      this.setState({ focused: false, showValue: this.props.value });
    }

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.notEmpty) {
      this.setState({ focused: true });
    }

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  render() {
    const { notEmpty, step = 1, value, className, ...props } = this.props;

    return (
      <div className={style.inputWrapper}>
        <TextField
          type="number"
          {...props}
          className={classNames(className, style.input)}
          value={notEmpty ? this.state.showValue : value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
        />

        <Icon
          type="arrowTop"
          className={classNames(style.arrow, style.arrow__up)}
          onClick={this.onIncrement(+step)}
        />

        <Icon
          type="arrowBottom"
          className={classNames(style.arrow, style.arrow__down)}
          onClick={this.onIncrement(-step)}
        />
      </div>
    );
  }
}

export default NumberField;
