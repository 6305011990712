import {
  dateAdd,
  MILLISECONDS_PER_MINUTE,
  setTimeToDate,
} from 'helpers/datetime';
import { Event } from 'types/entities';

const HOUR_TO_BLOCK_CATERING_FOR_NEXT_DAY = 14;
const HOUR_TO_BLOCK_EDIT_CATERING = 24;

export function getEventEnd(start: Date, duration: number) {
  return new Date(+start + duration * MILLISECONDS_PER_MINUTE);
}

export function calcEventPreparationStart(
  start: Date,
  preparationDuration: number,
) {
  return new Date(+start - preparationDuration * MILLISECONDS_PER_MINUTE);
}

export function calcEventCleaningEnd(
  start: Date,
  duration: number,
  cleaningDuration: number,
) {
  return new Date(
    +start + (duration + cleaningDuration) * MILLISECONDS_PER_MINUTE,
  );
}

export function getEventPreparationStart(event: Event) {
  return calcEventPreparationStart(event.start, event.preparation);
}

export function getEventCleaningEnd(event: Event) {
  return calcEventCleaningEnd(event.start, event.duration, event.preparation);
}

export function isCateringBlockedByTime(bookingDateTime: Date): boolean {
  const currentDate = setTimeToDate(new Date(), 0);
  const nextDay = setTimeToDate(new Date(), 0);
  nextDay.setDate(nextDay.getDate() + 1);
  const bookingDate = setTimeToDate(bookingDateTime, 0);

  return (
    (new Date().getHours() >= HOUR_TO_BLOCK_CATERING_FOR_NEXT_DAY &&
      bookingDate.getTime() <= nextDay.getTime()) ||
    bookingDate.toISOString() === currentDate.toISOString()
  );
}

export function isCateringEditBlocked(bookingDateTime: Date): boolean {
  return (
    new Date() > dateAdd(bookingDateTime, -HOUR_TO_BLOCK_EDIT_CATERING, 'hour')
  );
}
