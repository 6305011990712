import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import style from './Field.module.scss';

interface IWrapperProps {
  children: ReactNode;
}

interface IErrorProps {
  show: boolean;
  messageId: string;
}

const Wrapper = ({ children }: IWrapperProps) => {
  return <div className={style.wrapper}>{children}</div>;
};

const Error = ({ show, messageId }: IErrorProps) => {
  if (!show) {
    return null;
  }

  return (
    <div className={style.error}>
      <FormattedMessage id={messageId} />
    </div>
  );
};

export const Field = {
  Wrapper: observer(Wrapper),
  Error,
};
