import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import AppRouter from 'stores/AppRouter';
import InvoiceStore from 'stores/InvoicesStore';
import StatisticsStore from 'stores/StatisticsStore';
import { ROUTES } from 'routes/routes';
import { dateToString } from 'helpers/datetime';

import FavoriteMenu from 'components/FavoriteMenu/FavoriteMenu';
import InnerCalendar from 'components/InnerCalendar/InnerCalendar';
import InvoiceCard from 'components/InvoiceCard/InvoiceCard';
import Statistic from 'components/Panels/Statistic/Statistic';
import {
  tileDisabled,
  tileHolidayClassName,
} from 'components/InnerCalendar/helpers';

import style from './Dashboard.module.scss';
import RootStore from 'stores/RootStore';

@observer
class Dashboard extends Component {
  invoiceStore = new InvoiceStore();
  statistics = new StatisticsStore();

  async componentDidMount() {
    await Promise.all([this.invoiceStore.init(), this.statistics.init()]);
  }

  onChangeCalendar = (date: Date) => {
    AppRouter.router.push({
      pathname: ROUTES.bookingNew,
      state: {
        date: dateToString(date),
      },
    });
  };

  render() {
    const statisticData = {
      title: (
        <Link to={ROUTES.menuInvoices} className={style.link}>
          <FormattedMessage id="state.title.invoice" />
        </Link>
      ),
      values: [
        {
          name: <FormattedMessage id="state.inApproval" />,
          value: this.statistics.data.openedInvoices,
        },
        {
          name: <FormattedMessage id="state.approved" />,
          value: this.statistics.data.acceptedInvoices,
        },
        {
          name: <FormattedMessage id="state.rejected" />,
          value: this.statistics.data.declinedInvoices,
        },
      ],
    };

    return (
      <div className={style.dashboard}>
        <div className={style.favoritesAndCalendarSection}>
          <section className={style.favoritesSection}>
            <div className={style.sectionTitle}>
              {RootStore.config.appSchema === 'gandh' ? (
                <FormattedMessage id="secretaryPage.menuSection.title" />
              ) : (
                <FormattedMessage id="secretaryPage.favoritesSection.title" />
              )}
            </div>

            <FavoriteMenu />
          </section>

          <section className={style.calendarSection}>
            <InnerCalendar
              onChange={this.onChangeCalendar}
              light
              minDate={new Date()}
              tileDisabled={tileDisabled}
              tileClassName={tileHolidayClassName}
            />
          </section>
        </div>

        <section className={style.invoiceSection}>
          <div className={style.wrapper}>
            <Statistic
              schema="color"
              data={statisticData}
              className={style.statistic}
            />

            <ul className={style.invoices}>
              {this.invoiceStore.sortedInvoices.map(invoice => (
                <Link
                  to={{
                    pathname: ROUTES.menuInvoices,
                    hash: invoice.id,
                  }}
                  key={invoice.id}
                  className={style.link__invoice}
                >
                  <InvoiceCard invoice={invoice} className={style.invoice} />
                </Link>
              ))}
            </ul>
          </div>
        </section>
      </div>
    );
  }
}

export default Dashboard;
