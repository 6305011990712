export const ROUTES = {
  root: '/',
  login: '/login',
  recover: '/recover',
  dashboard: '/dashboard',
  profile: '/profile',
  staffDashboard: '/staff',
  bookingNew: '/booking/new',
  bookingEdit: '/booking/edit/:id',
  cateringEdit: '/catering-edit/:id/room/:roomId',

  users: '/users',
  user: '/user/:id?',

  menu: '/menu',
  manager: '/manager',
  menuEvents: '/menu/events',
  menuInvoices: '/menu/invoices',
  menuReport: '/menu/report',

  event: '/events/:id',
};
