import React from 'react';

import style from './Tabs.module.scss';
import classNames from 'classnames';
import Button from 'components/Button/Button';

interface ITab<T> {
  id: string;
  title: T;
}

interface IProps<T> {
  tabs: Array<ITab<T>>;
  activeTabId: string;
  onTabClick: (id: string) => void;
}

export const Tabs = <T,>({ tabs, activeTabId, onTabClick }: IProps<T>) => {
  return (
    <ul className={style.types}>
      {tabs.map(tab => {
        const onClick = () => {
          onTabClick(tab.id);
        };

        return (
          <li
            key={tab.id}
            className={classNames(style.type, {
              [style.type__active]: tab.id === activeTabId,
            })}
          >
            <Button className={style.typeBtn} onClick={onClick}>
              {tab.title}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};
