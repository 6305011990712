import React, { Component } from 'react';
import { observer } from 'mobx-react';

import CateringStore from 'stores/CateringStore';

import CateringSectionProduct from 'components/Panels/CateringSection/CateringSectionProduct';

import style from './CateringSection.module.scss';

interface Props {
  store: CateringStore;
  className?: string;
  extraMenu?: boolean;
}

@observer
class CateringSection extends Component<Props> {
  toggleProduct = id => () => {
    this.props.store.toggleProduct(id);
  };

  render() {
    const { store, extraMenu = false } = this.props;
    const menu = extraMenu ? store.extraMenu : store.menu;

    if (!store.menu.length) {
      return null;
    }

    return (
      <div className={style.productsSection}>
        <ul className={style.products}>
          {menu.map(product => (
            <CateringSectionProduct
              product={product}
              key={product.id}
              increase={store.increaseProductAmount(product)}
              reduce={store.reduceProductAmount(product)}
              remove={this.toggleProduct(product.id)}
              editable={store.canEditProduct(product)}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default CateringSection;
