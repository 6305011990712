import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';
import { ToastOptions } from 'react-toastify';

import style from './Notification.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import { dateAdd } from 'helpers/datetime';

import { replaceUrlParams } from 'http/helpers';

import NotificationStore from 'stores/NotificationStore';

import { ROUTES } from 'routes/routes';

import {
  NotificationModel,
  NotificationPriority,
  NotificationType,
} from 'types/notification';

interface Props extends ToastOptions {
  notification: NotificationModel;
  close: () => void;
}

const Notification = (props: Props) => {
  const { id, message, priority, params, targetId, type } = props.notification;

  const link =
    type === NotificationType.event
      ? replaceUrlParams(ROUTES.event, { id: targetId })
      : {
          pathname: ROUTES.menuInvoices,
          hash: targetId,
          state: { id: targetId },
        };

  const onClick = async () => {
    await NotificationStore.acceptNotification(id);
    props.close();
  };

  return (
    <div
      key={id}
      className={classNames(style.notification, {
        [style.notification__warning]: priority === NotificationPriority.medium,
        [style.notification__info]: priority === NotificationPriority.low,
      })}
    >
      <FormattedMessage
        id={message}
        values={{
          ...params,
          date: (
            <FormattedDate
              value={params.start}
              year="numeric"
              month="2-digit"
              day="2-digit"
            />
          ),
          start: <FormattedTime value={params.start} />,
          end: (
            <FormattedTime
              value={dateAdd(params.start, params.duration, 'minute')}
            />
          ),
        }}
      />

      <div className={style.btnRow}>
        <Button
          styleType="text"
          className={style.btn__accept}
          onClick={onClick}
        >
          <Icon type="accept" />
        </Button>

        <Link to={link} className={style.btn__show} onClick={onClick}>
          <FormattedMessage id="button.lookAt" />
        </Link>
      </div>
    </div>
  );
};

export default Notification;
