import { Category, CurrencyValue } from 'types/entities';

export class ReportModel {
  brutto: CurrencyValue = 0;
  netto: CurrencyValue = 0;
  profit: CurrencyValue = 0;
  person = 'alle';
  eventsCount = 0;
  categories: Category[] = [];
}
