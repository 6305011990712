import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl,
} from 'react-intl';
import Modal from 'rc-dialog';

import style from '../Modals.module.scss';

import Button from 'components/Button/Button';

import { formatMessages } from 'helpers/intl';
import { ModalProps, DialogActionType } from 'stores/ModalStore';

interface Props extends ModalProps, WrappedComponentProps {
  inputLabel: string;
  inputPlaceholder: string;
  okBtnText: string;
  cancelBtnText: string;
}

@observer
class CommentModal extends Component<Props> {
  @observable text = '';

  @action
  onChangeText = e => {
    this.text = e.target.value;
  };

  onOk = () => {
    this.props.onClose(DialogActionType.submit, this.text);
  };

  onCancel = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  render() {
    const {
      inputLabel,
      inputPlaceholder,
      okBtnText,
      cancelBtnText,
      intl,
    } = this.props;

    const formatMessage = formatMessages(intl);

    return (
      <Modal
        className={style.modal__condition}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
        closable={false}
      >
        <div className={style.label}>
          <FormattedMessage id={inputLabel} />
        </div>

        <input
          className={style.input}
          value={this.text}
          onChange={this.onChangeText}
          placeholder={formatMessage(inputPlaceholder)}
          maxLength={2000}
        />

        <div className={style.buttons}>
          <Button
            className={style.btn}
            styleType="ghost"
            onClick={this.onCancel}
          >
            <FormattedMessage id={cancelBtnText} />
          </Button>

          <Button
            className={style.btn}
            styleType="reject"
            onClick={this.onOk}
            disabled={!this.text}
          >
            <FormattedMessage id={okBtnText} />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(CommentModal);
