import React from 'react';
import classNames from 'classnames';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

import { dateAdd } from 'helpers/datetime';

import { Invoice, InvoiceStatus } from 'types/entities';

import { ROUTES } from 'routes/routes';

import { replaceUrlParams } from 'http/helpers';

interface Props {
  item: Invoice;
  className?: string;
  onClick?: () => void;
}

const InvoiceItem = (props: Props) => {
  const { item, className, onClick } = props;

  return (
    <div
      className={classNames('grid-row', 'item', className, {
        item__accepted: item.status === InvoiceStatus.accepted,
        item__declined: item.status === InvoiceStatus.declined,
        item__inApproval:
          item.status === InvoiceStatus.inApproval ||
          item.status === InvoiceStatus.draft,
        item__invoice: true,
      })}
      onClick={onClick}
      id={item.id}
    >
      <div className="column justify__between">
        <FormattedDate
          value={item.event.start}
          year="numeric"
          month="2-digit"
          day="2-digit"
        />

        <div className="name">
          <FormattedTime value={item.event.start} />
          &nbsp;-&nbsp;
          <FormattedTime
            value={dateAdd(item.event.start, item.event.duration, 'minute')}
          />
        </div>
      </div>

      <div className="column justify__between">
        <span className="id">{item.number}</span>

        <Link
          to={replaceUrlParams(ROUTES.event, { id: item.event.id })}
          className="title"
          onClick={e => e.stopPropagation()}
        >
          {item.event.title}
        </Link>
      </div>

      <div className="column">
        {item.event.reservations.map(reservation => (
          <p key={reservation.id}>{reservation.room.title}</p>
        ))}
      </div>
      <div className="column">
        <FormattedCurrency value={item.event.totalPrice} />
      </div>
    </div>
  );
};

export default InvoiceItem;
