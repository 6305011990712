import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useAlert } from 'hooks/useAlert';
import { UserPriceGroup } from 'types/entities';
import { UsersStore } from 'stores/UsersStore';

import Select, { Option } from 'components/Form/Fields/Select/Select';
import { UserSelectNoOptionsMessage } from 'components/UserSelect/UserSelectNoOptionsMessage';
import AlertModal from 'components/Modals/AlertModal/AlertModal';

import style from './UserSelect.module.scss';

interface Props {
  userId: string;
  onChangeUserId: (
    id: string,
    name: string,
    priceGroup: UserPriceGroup,
  ) => void;
  disabled?: boolean;
}

function UserSelect({ userId, onChangeUserId, disabled }: Props) {
  const [usersStore] = useState(new UsersStore(true));
  const [alertMessage, setAlertMessage] = useAlert();
  const onChange = (
    v: string | null | undefined,
    option: Option<string> | undefined | null,
  ) => {
    if (option) {
      const { value, label, payload } = option;
      onChangeUserId(value, label as string, payload as UserPriceGroup);
    }
  };

  useEffect(() => {
    usersStore.fetchUsers();
  }, [usersStore]);

  return (
    <>
      <Select
        value={userId}
        onChange={onChange}
        options={usersStore.usersOptions}
        isSearchable={true}
        className={style.select}
        noOptionsMessage={() => (
          <UserSelectNoOptionsMessage
            onUserCreated={() => {
              usersStore.fetchUsers();
              setAlertMessage('createUserModal.user.created');
            }}
          />
        )}
        disabled={disabled}
        isLoading={usersStore.isLoading}
      />

      {alertMessage && (
        <AlertModal
          message={alertMessage}
          onClose={() => setAlertMessage('')}
        />
      )}
    </>
  );
}

export default observer(UserSelect);
