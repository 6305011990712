import axios, { AxiosError } from 'axios';

import router from 'stores/AppRouter';
import ModalStore from 'stores/ModalStore';
import RootStore from 'stores/RootStore';

import { ROUTES } from 'routes/routes';

import fileReader from 'helpers/file';

import { isAuthTokenExpired } from './helpers';

export const setAuthInterceptor = (config: any) => {
  const { user } = RootStore;
  const tokens = user.getAuthTokens();

  if (tokens.accessToken) {
    config.headers.Authorization = `Bearer ${tokens.accessToken}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
};

export async function updateAuthInterceptor(error: AxiosError) {
  const { refreshTokens, getAuthTokens } = RootStore.user;
  const { refreshToken } = getAuthTokens();
  const { response } = error;

  if (response && response.status === 401 && response.data) {
    let errors;

    if (error.config.responseType === 'blob') {
      const file = (await fileReader(response.data)) as any;

      ({ errors } = JSON.parse(file));
    } else {
      ({ errors } = response.data);
    }

    if (
      refreshToken &&
      Array.isArray(errors) &&
      errors.length &&
      isAuthTokenExpired(errors)
    ) {
      try {
        await refreshTokens(refreshToken);
        setAuthInterceptor(response.config);

        return axios.request(response.config);
      } catch (err) {
        if (
          err.response?.status === 400 &&
          err.response?.data?.error === 'invalid_grant'
        ) {
          RootStore.user.logOut();
          router.push(ROUTES.login);
        }

        return Promise.reject(error);
      }
    }
  }

  return Promise.reject(error);
}

export function unauthorizedInterceptor(error: AxiosError) {
  const { response } = error;

  if (response && response.status === 401) {
    RootStore.user.logOut();
    router.push(ROUTES.login);
  }

  return Promise.reject(error);
}

export function internalServerErrorInterceptor(error: AxiosError) {
  const { response } = error;

  if (response && response.status >= 500) {
    ModalStore.closeAllModal();
    RootStore.internalServerError = true;
  }

  return Promise.reject(error);
}

export function accessDeniedErrorInterceptor(error: AxiosError) {
  const { response } = error;

  if (
    response &&
    response.status === 403 &&
    response.config.method &&
    response.config.method.toUpperCase() === 'GET'
  ) {
    ModalStore.closeAllModal();
    RootStore.accessDeniedError = true;
  }

  return Promise.reject(error);
}
