import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import ValidationMessage from 'components/Modals/ChangePasswordModal/ValidationMessage';
import style from './ChangePassword.module.scss';

@observer
class ValidationBlock extends Component<{
  validationStatus: any;
}> {
  render() {
    const {
      isValidCharactersNumber,
      isValidHaveNumber,
      isValidUppercaseAndLowercase,
    } = this.props.validationStatus;

    return (
      <div className={style.validation__block}>
        <div className={style.validation__title}>
          <FormattedMessage id={'profile.password.validation.title'} />
        </div>
        <ValidationMessage
          isValid={isValidCharactersNumber}
          message={'profile.password.validation.charactersNumber'}
        />
        <ValidationMessage
          isValid={isValidHaveNumber}
          message={'profile.password.validation.haveNumber'}
        />
        <ValidationMessage
          isValid={isValidUppercaseAndLowercase}
          message={'profile.password.validation.uppercase.and.lowercase'}
        />
      </div>
    );
  }
}

export default ValidationBlock;
