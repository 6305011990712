import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import style from './ManagerPage.module.scss';

import Icon from 'components/Icon/Icon';
import Statistic from 'components/Panels/Statistic/Statistic';

import ManagerDashboardStore from 'stores/ManagerDashboardStore';
import StatisticsStore from 'stores/StatisticsStore';
import { ROUTES } from 'routes/routes';

import Tabs from './Tabs';

@observer
class ManagerPage extends Component {
  eventsStore = new ManagerDashboardStore();
  statistics = new StatisticsStore();

  async componentDidMount() {
    await Promise.all([
      this.eventsStore.init(new Date()),
      this.statistics.init(),
    ]);
  }

  render() {
    const bookingStatistic = {
      title: (
        <Link to={ROUTES.menuEvents} className={style.link}>
          <FormattedMessage id="state.title.booking" />
          <Icon type="arrowRight" className={style.icon__arrow} />
        </Link>
      ),
      values: [
        {
          name: <FormattedMessage id="state.date.this.week" />,
          value: this.statistics.data.thisWeekBookings,
        },
        {
          name: <FormattedMessage id="state.date.next.week" />,
          value: this.statistics.data.nextWeekBookings,
        },
        {
          name: <FormattedMessage id="state.date.later" />,
          value: this.statistics.data.laterBookings,
        },
      ],
    };

    const invoiceStatistic = {
      title: (
        <Link to={ROUTES.menuInvoices} className={style.link}>
          <FormattedMessage id="state.title.invoice" />
          <Icon type="arrowRight" className={style.icon__arrow} />
        </Link>
      ),
      values: [
        {
          name: <FormattedMessage id="state.inApproval" />,
          value: this.statistics.data.openedInvoices,
        },
        {
          name: <FormattedMessage id="state.approved" />,
          value: this.statistics.data.acceptedInvoices,
        },
        {
          name: <FormattedMessage id="state.rejected" />,
          value: this.statistics.data.declinedInvoices,
        },
      ],
    };

    return (
      <>
        <div className={style.content}>
          <div className={style.header}>
            <Icon type="chair" className={style.header__icon} />

            <span className={style.header__name}>
              <FormattedMessage id="booking.today" />
            </span>
          </div>

          <div className={style.main}>
            <Tabs store={this.eventsStore} />

            <section className={style.summary}>
              <div className={style.statisticWrapper}>
                <Statistic
                  data={bookingStatistic}
                  className={style.statistic}
                />
              </div>

              <div className={style.statisticWrapper}>
                <Statistic
                  data={invoiceStatistic}
                  className={style.statistic}
                />
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default ManagerPage;
