import React from 'react';
import Modal from 'rc-dialog';
import { DialogActionType, ModalProps } from 'stores/ModalStore';
import style from './InfoModal.module.scss';

interface IProps extends ModalProps {
  message: string | JSX.Element;
}

export const InfoModal = ({ message, onClose }: IProps) => {
  const onModalClose = () => {
    onClose(DialogActionType.cancel);
  };

  return (
    <Modal
      className={style.modal}
      animation="zoom"
      maskAnimation="fade"
      visible
      destroyOnClose={true}
      closable={true}
      onClose={onModalClose}
    >
      {message}
    </Modal>
  );
};
