import React, { Component } from 'react';

import style from './FavoriteMenu.module.scss';

import { appUrl } from 'http/helpers';

import { MenuSet } from 'types/entities';

interface Props {
  menuItem: MenuSet;
  onClick: () => void;
}

class FavoriteMenuItem extends Component<Props> {
  render() {
    const { menuItem, onClick } = this.props;

    return (
      <div className={style.card} onClick={onClick}>
        {/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
          menuItem.image,
        ) ? (
          <img
            className={style.cardImage}
            src={appUrl(menuItem.image)}
            alt={menuItem.title}
          />
        ) : (
          <div className={style.cardImage} />
        )}
        <span className={style.cardLabel}>{menuItem.title}</span>
      </div>
    );
  }
}

export default FavoriteMenuItem;
