import { action, computed, observable } from 'mobx';
import AppRouter from 'stores/AppRouter';

export interface StepStore {
  title: string;
  valid: boolean;
}

class StepperStore {
  @observable steps: StepStore[];
  @observable currentStep = 0;

  constructor(steps: StepStore[]) {
    this.steps = steps;
  }

  disabledStep = (index: number) => {
    return computed(() => {
      return this.currentStep === index
        ? true
        : index === 0
        ? false
        : this.steps.slice(0, index).some(step => !step.valid);
    });
  };

  @computed
  get currentStepTitle() {
    return this.steps[this.currentStep].title;
  }

  @computed get activeIndex() {
    return this.currentStep + 1;
  }

  @action.bound
  setActiveStep = index => () => {
    this.currentStep = index;
  };

  @action.bound
  nextStep() {
    this.currentStep = Math.min(++this.currentStep, this.steps.length - 1);
  }

  @action.bound
  previousStep() {
    if (this.currentStep === 0) {
      AppRouter.goBack();
      return;
    }
    this.currentStep--;
  }
}

export default StepperStore;
