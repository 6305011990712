import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import style from './Button.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  shadow?: boolean;
  light?: boolean;
  styleType?: 'accept' | 'reject' | 'ghost' | 'text';
}

const Button = ({
  className,
  children,
  styleType,
  light,
  shadow = false,
  ...props
}: Props) => (
  <button
    className={classNames(style.btn, className, {
      [style.btn__accept]: styleType === 'accept',
      [style.btn__ghost]: styleType === 'ghost',
      [style.btn__reject]: styleType === 'reject',
      [style.btn__text]: styleType === 'reject',
      [style.color]: light,
      [style.shadow]: shadow,
    })}
    {...props}
  >
    {children}
  </button>
);

export default Button;
