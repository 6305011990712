import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import style from './RoomSlider.module.scss';

import { appUrl } from 'http/helpers';

import Slider from 'components/Slider/Slider';
import Spinner from 'components/Spinner/Spinner';

interface Props {
  images: string[];
  styles?: {
    wrapperImage?: string;
    slider?: string;
  };
}

@observer
class RoomSlider extends Component<Props> {
  @observable isLoading = true;

  onImageLoad = () => {
    this.isLoading = false;
  };

  render() {
    const { images, styles } = this.props;

    return (
      <Slider
        className={classNames(
          style.sliderComponent,
          style.slider,
          styles?.slider,
        )}
      >
        {images.map(it => (
          <div
            key={it}
            className={classNames(style.wrapper__image, styles?.wrapperImage)}
          >
            {/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
              it,
            ) ? (
              <>
                <img
                  className={style.room__image}
                  onLoad={this.onImageLoad}
                  src={appUrl(it)}
                  alt="room"
                />

                {this.isLoading && <Spinner className={style.spinner} />}
              </>
            ) : (
              <div className={style.room__image} />
            )}
          </div>
        ))}
      </Slider>
    );
  }
}

export default RoomSlider;
