import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Counter from 'components/Counter/Counter';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

import style from './CateringSection.module.scss';

import { MenuItem } from 'types/entities';

interface Props {
  product: MenuItem;
  increase?: () => void;
  reduce?: () => void;
  remove?: () => void;
  editable?: boolean;
}

@observer
class CateringSectionProduct extends Component<Props> {
  edit = value => {
    this.props.product.amount = value;
  };

  render() {
    const { product, increase, reduce, remove, editable = true } = this.props;

    return (
      <li className={style.product}>
        <div className={style.product__title}>{product.title}</div>
        <Button onClick={remove} className={style.button__close}>
          <Icon type="close" />
        </Button>
        <Counter
          value={product.amount}
          increase={increase}
          reduce={reduce}
          edit={editable ? this.edit : undefined}
          className={style.counter}
        />
        <div className={style.product__price}>
          <FormattedCurrency value={product.amount * product.price} />
        </div>
      </li>
    );
  }
}

export default CateringSectionProduct;
