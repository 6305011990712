import { ResponseErrMessage, ResponseError } from './types';

import qs from 'qs';

import Log from 'helpers/log';

export function appUrl(url: string): string {
  return `/api/conf${url}`;
}

export function mockUrl(url: string): string {
  return `/mock${url}.json`;
}

export function replaceUrlParams(url: string, params: object = {}): string {
  return url.replace(/:(\w+)/gi, (str, bracket) => {
    if (bracket in params) {
      return params[bracket];
    } else {
      Log.error("url param doesn't exist");
      return '';
    }
  });
}

export function arrayParamsSerializer(requestParams: any): string {
  return qs.stringify(requestParams, { indices: false });
}

export const isAuthTokenExpired = (errors: ResponseError[]): boolean =>
  errors.some(
    err => err.message === ResponseErrMessage.AUTH_TOKEN_EXPIRED_ERROR,
  );

export function filterEmptyFields<T>(object: T): T {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) => {
      return value !== undefined && value !== '';
    }),
  ) as T;
}
