import React, { ReactNode } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import classNames from 'classnames';

import style from './SearchField.module.scss';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';

interface Props {
  onChange: (value: string) => void;
  value: string;
  className?: string;
  children?: ReactNode;
  maxLength?: number;
}

const SearchField = ({
  onChange,
  value,
  intl,
  className,
  children,
  ...props
}: Props & WrappedComponentProps) => {
  const onSearchChange = evt => onChange(evt.target.value);
  const onReset = () => onChange('');

  return (
    <div className={classNames(className, style.wrapper)}>
      <Icon type="search" className={style.searchIcon} />

      <input
        type="search"
        placeholder={intl.formatMessage({ id: 'SEARCH_PLACEHOLDER' })}
        value={value}
        onChange={onSearchChange}
        className={style.input}
        {...props}
      />

      {children}

      {value && (
        <Button
          styleType="text"
          onClick={onReset}
          className={style.reset__button}
        />
      )}
    </div>
  );
};

export default injectIntl(SearchField);
