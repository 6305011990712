import React, { Component } from 'react';
import { observer } from 'mobx-react';

import './Modals.module.scss';

import ModalStore from 'stores/ModalStore';

@observer
class Modals extends Component {
  static close() {
    ModalStore.closeModal();
  }

  render() {
    const modalList = ModalStore.modalList;

    return modalList.map(modal => {
      const ModalComponent = modal.Component;

      return (
        <ModalComponent
          key={modal.id}
          onClose={ModalStore.closeModal}
          {...modal.props}
        />
      );
    });
  }
}

export default Modals;
