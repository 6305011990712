import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './MenuPage.module.scss';

import Icon from 'components/Icon/Icon';
import EventsGrid from './EventsGrid';
import InvoiceGrid from './InvoiceGrid';
import Reporting from './Reporting/Reporting';

import { ROUTES } from 'routes/routes';
import PrivateRouter from 'routes/PrivateRouter';

import NotificationStore from 'stores/NotificationStore';

import { NotificationType } from 'types/notification';

import { getRole, Roles } from 'helpers/roles';

@observer
class MenuPage extends Component {
  showLatestNotifications = (type: NotificationType) => () =>
    NotificationStore.showLatestNotifications(type);

  render() {
    const countEventNotification = NotificationStore.countNotificationByType(
      NotificationType.event,
    ).get();
    const countInvoiceNotification = NotificationStore.countNotificationByType(
      NotificationType.invoice,
    ).get();

    const isManager = getRole(Roles.CATERER_MANAGER);

    return (
      <>
        <div className={style.navigation}>
          <div className={style.row}>
            <NavLink
              to={ROUTES.menuEvents}
              className={style.link}
              activeClassName={style.link__active}
            >
              <Icon type="clock48" className={style.icon} />
              <FormattedMessage id="booking" />
              {!!countEventNotification && (
                <div
                  className={style.notificationCount}
                  onClick={this.showLatestNotifications(NotificationType.event)}
                >
                  {countEventNotification}
                </div>
              )}
            </NavLink>

            <NavLink
              to={ROUTES.menuInvoices}
              className={classNames(style.link, style.link__middle)}
              activeClassName={style.link__active}
            >
              <Icon type="bill48" className={style.icon} />
              <FormattedMessage id="settlements" />
              {!!countInvoiceNotification && (
                <div
                  className={style.notificationCount}
                  onClick={this.showLatestNotifications(
                    NotificationType.invoice,
                  )}
                >
                  {countInvoiceNotification}
                </div>
              )}
            </NavLink>

            {isManager && (
              <NavLink
                to={ROUTES.menuReport}
                className={style.link}
                activeClassName={style.link__active}
              >
                <Icon type="reporting48" className={style.icon} />
                <FormattedMessage id="reporting" />
              </NavLink>
            )}
          </div>
        </div>

        <div className={style.content}>
          <Switch>
            <Route path={ROUTES.menuEvents} component={EventsGrid} />
            <Route path={ROUTES.menuInvoices} component={InvoiceGrid} />
            <PrivateRouter
              path={ROUTES.menuReport}
              component={Reporting}
              roles={[Roles.CATERER_MANAGER]}
            />

            <Redirect to={ROUTES.menuEvents} />
          </Switch>
        </div>
      </>
    );
  }
}

export default MenuPage;
