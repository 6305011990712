import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import StepList from 'pages/BookingPage/Navigation/StepList';
import Setup from 'pages/BookingPage/Setup';
import PlannerStep from './Planner/PlannerStep';
import Summary from './Summary';

import BookingStore from 'stores/Booking/BookingStore';

type Props = RouteComponentProps<{ id?: string }>;

@observer
class BookingPage extends Component<Props> {
  booking: BookingStore;

  constructor(props: Props) {
    super(props);

    const id = this.props.match.params && this.props.match.params.id;
    // @ts-ignore
    const { date, menuId } = this.props.location.state || {};

    this.booking = new BookingStore({
      id,
      date: date ? new Date(date) : undefined,
      menuId,
    });
  }

  async componentDidMount() {
    await this.booking.init();
  }

  render() {
    const { stepper } = this.booking;

    return (
      <StepList stepper={stepper}>
        <PlannerStep store={this.booking} />
        <Setup store={this.booking} />
        <Summary store={this.booking} />
      </StepList>
    );
  }
}

export default BookingPage;
