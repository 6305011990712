import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import { MILLISECONDS_PER_MINUTE, setTimeToDate } from 'helpers/datetime';
import noop from 'helpers/noop';
import RootStore from 'stores/RootStore';
import { EventStatus } from 'types/entities';
import RoomModel from 'stores/Models/RoomModel';
import ModalStore from 'stores/ModalStore';

import EventBooked from './EventBooked';
import EventCurrent from './EventCurrent';
import EventFree from './EventFree';
import localizer from './localizer';
import TimeSlotWrapper from './TimeSlotWrapper';
import Icon from 'components/Icon/Icon';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Button from 'components/Button/Button';
import RoomInfoModal from 'components/Modals/RoomInfoModal/RoomInfoModal';
import RoomSlider from 'components/RoomSlider/RoomSlider';

import style from './Planner.module.scss';

// const DragAndDropCalendar = withDragAndDrop(Calendar);

export interface PlannerEvent {
  id: string;
  startPreparation: Date;
  endCleaning: Date;
  duration: number;
  preparation: number;
  cleaning: number;
  roomId: string;
  status: EventStatus;
  availableNew?: boolean;
  canBeRejected: boolean;
}

interface Props {
  date: Date;
  resource: Array<{
    id: string;
    title: string;
  }>;
  events: PlannerEvent[];
  duration: number;
  onSelect: (roomId: string) => void;
  maxPreparation: number;
  maxCleaning: number;
}

@observer
class Planner extends Component<Props> {
  onRoomInfoClick = (room: RoomModel) => () => {
    ModalStore.showModal(RoomInfoModal, { room });
  };
  // For drag'n'drop

  // moveEvent = ({ event, start, end, resourceId, ...props }) => {
  //   // console.log('moveEvent', { event, start, end, resourceId, ...props });
  //   const { events } = this.state;

  //   const idx = events.indexOf(event);

  //   const updatedEvent = { ...event, start, end, resourceId };

  //   const nextEvents = [...events];
  //   nextEvents.splice(idx, 1, updatedEvent);

  //   this.setState({
  //     events: nextEvents,
  //   });

  //   // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  // };

  // resizeEvent = ({ event, start, end, ...props }) => {
  //   // console.log('resizeEvent', { event, start, end, ...props });
  //   const { events } = this.state;

  //   const nextEvents = events.map(existingEvent => {
  //     return existingEvent.id === event.id
  //       ? { ...existingEvent, start, end }
  //       : existingEvent;
  //   });

  //   this.setState({
  //     events: nextEvents,
  //   });

  //   // alert(`${event.title} was resized to ${start}-${end}`)
  // };

  render() {
    const {
      date,
      events,
      resource,
      duration,
      maxCleaning,
      maxPreparation,
    } = this.props;

    return (
      <Calendar
        min={setTimeToDate(
          date,
          RootStore.config.eventTimeMin -
            maxPreparation * MILLISECONDS_PER_MINUTE,
        )}
        max={setTimeToDate(
          date,
          RootStore.config.eventTimeMax + maxCleaning * MILLISECONDS_PER_MINUTE,
        )}
        localizer={localizer()}
        events={events}
        onNavigate={noop}
        // onEventDrop={this.moveEvent}
        // onEventResize={this.resizeEvent}
        defaultView="day"
        date={date}
        step={5}
        timeslots={3}
        toolbar={false}
        components={{
          event: eventProps =>
            eventProps.event.availableNew ? (
              <EventFree {...eventProps} onSelect={this.props.onSelect} />
            ) : eventProps.event.current && !eventProps.event.duplicated ? (
              <EventCurrent {...eventProps} onSelect={this.props.onSelect} />
            ) : (
              <EventBooked {...eventProps} onSelect={this.props.onSelect} />
            ),
          timeSlotWrapper: TimeSlotWrapper,
          timeGutterHeader: () => (
            <span className={style.timeGutterHeader}>
              <FormattedMessage id="title.rooms" />
            </span>
          ),
        }}
        startAccessor="startPreparation"
        endAccessor="endCleaning"
        resources={resource}
        resourceAccessor="roomId"
        resourceIdAccessor="id"
        resourceTitleAccessor={(room: RoomModel) => (
          <div className={style.room__header}>
            <Button
              onClick={this.onRoomInfoClick(room)}
              styleType="text"
              className={style.btn__info}
            >
              <Icon type="info" />
            </Button>

            <RoomSlider
              images={room.images}
              styles={{
                wrapperImage: style.wrapper__image,
                slider: style.slider,
              }}
            />

            <div className={style.room__info}>
              <h3 className={style.room__title}>{room.title}</h3>

              <div className={style.info__line}>
                <Icon type="price" />
                <FormattedMessage id="title.cost" />
                <span className={style.info__value}>
                  <FormattedCurrency value={room.price(duration)} />
                </span>
              </div>

              <div className={style.info__line}>
                <Icon type="capacity" />
                <FormattedMessage id="title.capacity" />
                <span className={style.info__value}>{room.capacity}</span>
              </div>
            </div>
          </div>
        )}
        eventPropGetter={event => ({
          style: {
            zIndex: event.current ? 5 : 10,
          },
        })}
      />
    );
  }
}

export default Planner;
