import React, { Component } from 'react';
import { observer } from 'mobx-react';

import StepperStore from 'stores/Booking/StepperStore';

interface Props {
  stepper: StepperStore;
}

@observer
class StepList extends Component<Props> {
  render() {
    const { stepper, children } = this.props;

    return React.Children.toArray(children)[stepper.currentStep] || null;
  }
}

export default StepList;
