import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import style from './Spinner.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  fullscreen?: boolean;
  contain?: boolean;
}

const Spinner = (props: Props) => (
  <div
    className={classNames(style.container, props.className, {
      [style.fullscreen]: props.fullscreen,
      [style.contain]: props.contain,
    })}
    style={props.style}
  >
    <div className={style.spinner}>
      <div className={style.spinner__inner} />
    </div>

    {props.children}
  </div>
);

export default Spinner;
