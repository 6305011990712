import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';

import style from './RoomInfoModal.module.scss';

import { DialogActionType, ModalProps } from 'stores/ModalStore';
import RoomModel from 'stores/Models/RoomModel';

import Icon from 'components/Icon/Icon';
import RoomSlider from 'components/RoomSlider/RoomSlider';
import PriceTiers from './PriceTiers';

interface Props extends ModalProps {
  room: RoomModel;
}

@observer
class RoomInfoModal extends Component<Props> {
  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  render() {
    const { room } = this.props;

    return (
      <Modal
        className={style.modal}
        wrapClassName={style.wrapper__modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={this.onClose}
        visible
      >
        <h2 className={style.title}>{room.title}</h2>

        <RoomSlider
          images={room.images}
          styles={{
            wrapperImage: style.wrapper__image,
          }}
        />

        <div className={style.room__info}>
          <div className={style.info__line}>
            <Icon type="capacity" />
            <FormattedMessage id="title.capacity" />
            <span className={style.info__value}>{room.capacity}</span>
          </div>

          <div className={style.info__line}>
            <Icon type="price" />
            <FormattedMessage id="modal.roomInfo.cost" />

            {room.priceTiers && <PriceTiers priceTiers={room.priceTiers} />}
          </div>

          {Boolean(room.equipments.length) && (
            <div className={style.description}>
              <h3 className={style.description__title}>
                <FormattedMessage id="modal.roomInfo.amenities" />
              </h3>

              <div className={style.tags}>
                {room.equipments.map(it => (
                  <span key={it} className={style.tag}>
                    {it}
                  </span>
                ))}
              </div>
            </div>
          )}

          <div
            className={style.description}
            dangerouslySetInnerHTML={{ __html: room.description }}
          />
        </div>
      </Modal>
    );
  }
}

export default RoomInfoModal;
