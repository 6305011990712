import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

import Button from 'components/Button/Button';
import TextField from 'components/Form/Fields/TextField/TextField';
import PasswordField from 'components/Form/Fields/PasswordField/PasswordField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';

import LoginForm from 'stores/Forms/LoginForm';
import RootStore from 'stores/RootStore';
import { ROUTES } from 'routes/routes';

import style from './Login.module.scss';

@observer
class Login extends Component {
  store = new LoginForm();
  FormControl = bindFormControl(this.store);

  render() {
    const FormControl = this.FormControl;

    return (
      <section className={style.layout}>
        {this.store.pending && <Spinner contain />}

        <div className={style.wrapper__side}>
          <Icon type={RootStore.config.appSchema} className={style.logo} />
        </div>

        <div className={classNames(style.wrapper__side, style.wrapper__left)}>
          <div className={style.wrapper__form}>
            <form
              name="login-form"
              className={style.form}
              onSubmit={this.store.submit}
            >
              <h3 className={style.title}>
                <FormattedMessage id="page.login.title" />
              </h3>

              {this.store.message && (
                <div className={style.message}>
                  <FormattedMessage id={this.store.message} />
                </div>
              )}

              <div className={style.controls__form}>
                <FormControl
                  name="email"
                  className={style.control}
                  showError={!Boolean(this.store.message)}
                  render={props => <TextField {...props} />}
                />

                <FormControl
                  name="password"
                  className={style.control}
                  showError={!Boolean(this.store.message)}
                  render={props => <PasswordField {...props} />}
                />
              </div>

              <div className={style.wrapper__btn}>
                <Button
                  className={classNames(style.btn, style.btn__submit)}
                  styleType="accept"
                  type="submit"
                  shadow
                >
                  <FormattedMessage id="button.login" />
                </Button>
              </div>
            </form>
            <div className={style.link__wrapper}>
              <div className={style.link__note}>
                <FormattedMessage id="page.recover.note" />
              </div>

              <NavLink to={ROUTES.recover} className={style.link}>
                <FormattedMessage id="page.login.recover.link" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;
