import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';

import style from '../Modals.module.scss';

import Button from 'components/Button/Button';
import { ErrorMessages } from 'components/Errors/ErrorMessages';

import { ModalProps, DialogActionType } from 'stores/ModalStore';
import classNames from 'classnames';

interface Props extends ModalProps {
  error: { key: string; params?: any };
  cancelBtnText: string;
}

@observer
class ErrorModal extends Component<Props> {
  onClose = () => {
    this.props.onClose(DialogActionType.submit);
  };

  render() {
    const { error, cancelBtnText } = this.props;

    return (
      <Modal
        className={classNames(style.modal, style.modal__condition)}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={this.onClose}
        visible
      >
        <div className={style.text}>
          <ErrorMessages error={error} />
        </div>

        <div className={style.buttons}>
          <Button
            className={style.btn}
            styleType="reject"
            onClick={this.onClose}
          >
            <FormattedMessage id={cancelBtnText} />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ErrorModal;
