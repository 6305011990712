import { observable } from 'mobx';
import { Arrangement } from 'types/entities';

class ArrangementModel implements Arrangement {
  @observable capacity = 0;
  @observable description = '';
  @observable id = '';
  @observable image = '';
  @observable title = '';
}

export default ArrangementModel;
