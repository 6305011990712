import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';

import style from '../Modals.module.scss';

import Button from 'components/Button/Button';

import { ModalProps, DialogActionType } from 'stores/ModalStore';

interface Props extends ModalProps {
  text: string;
  okBtnText: string;
  cancelBtnText: string;
}

@observer
class ConfirmModal extends Component<Props> {
  onOk = () => {
    this.props.onClose(DialogActionType.submit);
  };

  onCancel = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  render() {
    const { text, okBtnText, cancelBtnText } = this.props;

    return (
      <Modal
        className={style.modal__condition}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
        closable={false}
      >
        <div className={style.text}>
          <FormattedMessage id={text} />
        </div>

        <div className={style.buttons}>
          <Button
            className={style.btn}
            styleType="reject"
            onClick={this.onCancel}
          >
            <FormattedMessage id={cancelBtnText} />
          </Button>

          <Button className={style.btn} type="submit" onClick={this.onOk}>
            <FormattedMessage id={okBtnText} />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ConfirmModal;
