import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Calendar from 'react-calendar/dist/entry.nostyle';
import classNames from 'classnames';

import style from './InnerCalendar.module.scss';

import RootStore from 'stores/RootStore';

interface General {
  onClickDay?: (date) => void;
  light?: boolean;
  className?: string;
  selectRange?: boolean;
  minDate?: Date;
  tileDisabled?: (props) => boolean;
  tileClassName?: string | string[] | ((props) => string | string[] | null);
}

interface PropsSingeValue extends General {
  onChange?: (value: Date) => void;
  value?: Date;
}

interface PropsRangeValue extends General {
  onChange?: (value: Date[]) => void;
  value?: Date[];
}

type Props = PropsSingeValue | PropsRangeValue;

@observer
class InnerCalendar extends Component<Props> {
  render() {
    const {
      className,
      selectRange = false,
      light,
      onClickDay,
      value,
      ...props
    } = this.props;

    return (
      <Calendar
        {...(props as any)}
        className={classNames(style.calendar, className, {
          [style.calendar__light]: light,
        })}
        selectRange={selectRange}
        onClickDay={onClickDay}
        value={value}
        minDetail="month"
        maxDetail="month"
        prev2Label={null}
        next2Label={null}
        locale={RootStore.localization.locale}
      />
    );
  }
}

export default InnerCalendar;
