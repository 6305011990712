import { appUrl } from '../helpers';
import http from '../http';
import URLS from '../urls';
import {
  NotificationPriority,
  NotificationType,
  NotificationStatus,
} from 'types/notification';
import { stringToDateTime } from 'helpers/datetime';

interface Notification {
  id: string;
  message: string;
  params: Record<string, string>;
  priority: NotificationPriority;
  status: NotificationStatus;
  targetId: 'string';
  targetUser: 'string';
  type: NotificationType;
}

export const notificationApi = {
  fetchNotifications(startAfterId?: string) {
    return http
      .get<Notification[]>(appUrl(URLS.notifications), {
        params: { startAfterId },
      })
      .then(response => ({
        ...response,
        data: response.data.map(notification => ({
          ...notification,
          params: {
            ...notification.params,
            ...('start' in notification.params
              ? { start: stringToDateTime(notification.params.start) }
              : {}),
          },
        })),
      }));
  },

  acceptNotification(id: string) {
    return http.put<undefined, Notification>(
      appUrl(URLS.notificationById),
      undefined,
      {
        replaceParams: {
          id,
        },
      },
    );
  },
};
