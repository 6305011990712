import React from 'react';
import SummaryPanel from 'components/Panels/Summary/SummaryPanel';

import { Event } from 'types/entities';

interface Props {
  item: Event;
  expanded: boolean;
}

const EventItemDetails = (props: Props) => {
  const { item, expanded } = props;

  return (
    <>
      {expanded &&
        item.reservations.map(reservation => (
          <SummaryPanel
            key={reservation.id}
            products={reservation.menu}
            roomInfo={{
              title: reservation.room.title,
              price:
                'undefined' === typeof reservation.roomPrice
                  ? 0
                  : reservation.roomPrice,
            }}
            className="squized__panel"
            splitMode
          />
        ))}
    </>
  );
};

export default EventItemDetails;
