import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';
import { DialogActionType, ModalProps } from 'stores/ModalStore';
import ChangePasswordForm from 'stores/Forms/ChangePasswordForm';
import Button from 'components/Button/Button';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import ValidationBlock from 'components/Modals/ChangePasswordModal/ValidationBlock';
import TextField from 'components/Form/Fields/TextField/TextField';
import style from './ChangePassword.module.scss';
import classNames from 'classnames';
import RootStore from 'stores/RootStore';

interface Props extends ModalProps {
  userId: string;
}

@observer
class ChangePasswordModal extends Component<Props> {
  form = new ChangePasswordForm(this.props.userId);
  formControl = bindFormControl(this.form);

  onActualPasswordChange = (onChange: (value: string) => void) => (
    value: string,
  ) => {
    onChange(value);
    this.form.actualPasswordError = false;
  };

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  onSubmit = async event => {
    event.preventDefault();
    const onFulfilled = payload =>
      this.props.onClose(DialogActionType.submit, payload);
    this.form.submit(onFulfilled);
  };

  render() {
    const FormControl = this.formControl;
    const {
      isValidCharactersNumber,
      isValidUppercaseAndLowercase,
      isValidHaveNumber,
    } = this.form;

    return (
      <Modal
        className={classNames(style.modal, RootStore.config.appSchema)}
        destroyOnClose={true}
        onClose={this.onClose}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <form name="comment-form" onSubmit={this.onSubmit}>
          <h3 className={style.title}>
            <FormattedMessage id={'profile.password.change'} />
          </h3>
          <div className={style.body}>
            <div className={style.form__row}>
              <FormControl
                className={style.form__control}
                name="previousPassword"
                render={props => (
                  <TextField
                    {...props}
                    onChange={this.onActualPasswordChange(props.onChange)}
                    invalid={props.invalid || this.form.actualPasswordError}
                  />
                )}
              />
              {this.form.actualPasswordError && (
                <p className={style.error}>
                  <FormattedMessage id="profile.password.actualPasswordError" />
                </p>
              )}
            </div>
            <div className={style.form__row}>
              <FormControl
                className={style.form__control}
                name="newPassword"
                render={props => <TextField {...props} />}
              />
            </div>
            <div className={style.form__row}>
              <FormControl
                className={style.form__control}
                name="confirmNewPassword"
                render={props => <TextField {...props} />}
              />
            </div>
            <ValidationBlock
              validationStatus={{
                isValidCharactersNumber,
                isValidHaveNumber,
                isValidUppercaseAndLowercase,
              }}
            />
          </div>
          <div className={style.form__controls}>
            <Button
              styleType="ghost"
              onClick={this.onClose}
              className={style.btn}
            >
              <FormattedMessage id="button.abort" />
            </Button>
            <Button type="submit" styleType="accept" className={style.btn}>
              <FormattedMessage id={'button.changePassword'} />
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
}

export { ChangePasswordModal };
