import { action, observable, reaction } from 'mobx';
import httpFacade from 'http/httpFacade';
import { ChangePasswordDTO } from 'http/Api/User/user';
import {
  BaseFormModel,
  isRequired,
  displayName,
  hasMaxLength,
  isRegEx,
  isSameAs,
  isNotSameAs,
} from '../BaseForm';
import noop from '../../helpers/noop';
import Log from '../../helpers/log';

class ChangePasswordForm extends BaseFormModel {
  @observable
  @displayName('PREVIOUS_PASSWORD')
  @isRequired()
  @hasMaxLength(25)
  previousPassword = '';

  @observable
  @displayName('NEW_PASSWORD')
  @isRequired()
  @hasMaxLength(25)
  @isRegEx(
    /(?=.*[A-ZÄÖÜ])(?=.*[a-zäöü])(?=.*[0-9])(?=.{8,})/,
    'VM_IS_REGEX_PASSWORD',
  )
  @isNotSameAs('previousPassword', 'VM_IS_NOT_SAME_AS_PASSWORD')
  newPassword = '';

  @observable
  @displayName('CONFIRM_NEW_PASSWORD')
  @isRequired()
  @hasMaxLength(25)
  @isSameAs('newPassword', 'VM_IS_SAME_AS_PASSWORD')
  confirmNewPassword = '';

  @observable readonly userId: string;

  @observable isValidCharactersNumber: boolean;
  @observable isValidUppercaseAndLowercase: boolean;
  @observable isValidHaveNumber: boolean;

  @observable actualPasswordError = false;

  constructor(userId: string) {
    super();
    this.userId = userId;
    this.newPassword = '';

    reaction(
      () => this.newPassword,
      () => {
        this.isValidHaveNumber = /(?=.*[0-9])/.test(this.newPassword);
        this.isValidUppercaseAndLowercase = /(?=.*[A-ZÄÖÜ])(?=.*[a-zäöü])/.test(
          this.newPassword,
        );
        this.isValidCharactersNumber = /(?=.{8,})/.test(this.newPassword);
      },
    );
  }

  @action.bound
  async submit(fullFilled = noop) {
    if (!this.validate()) {
      return;
    }

    try {
      const requestData: ChangePasswordDTO = {
        oldPassword: this.previousPassword,
        newPassword: this.newPassword,
      };
      const response = await httpFacade.user.changePassword(
        this.userId,
        requestData,
      );
      fullFilled(response.data);
    } catch (error) {
      if (error.response.status === 409) {
        this.actualPasswordError = true;
      }
      Log.warn(error);
    }
  }
}

export default ChangePasswordForm;
