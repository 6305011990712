import {
  MenuItemType,
  CurrencyValue,
  Allergen,
  MenuCategory,
  UserPriceGroup,
} from 'types/entities';

import { appUrl } from '../helpers';
import http from '../http';
import URLS from '../urls';

interface ResponseMenuItem {
  id: string;
  image: string;
  title: string;
  description: string;
  detailedDescription: string;
  favorite: boolean;
  category: {
    id: string;
    title: string;
    type: MenuItemType;
  };
  allergens: Allergen[];
  price: CurrencyValue;
}

export const menuApi = {
  fetchMenu(priceGroup: UserPriceGroup, forExtraBooking?: boolean) {
    const config = {
      params: {
        priceGroup,
        ...(forExtraBooking ? { forExtraBooking: true } : {}),
      },
    };

    return http.get<ResponseMenuItem[]>(appUrl(URLS.menu), config);
  },

  fetchMenuCategories() {
    return http.get<MenuCategory[]>(appUrl(URLS.menuCategories));
  },
};
