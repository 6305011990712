import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import { Category, ReportFilter, Secretary, SortConfig } from 'types/entities';

import { sortByAccessor } from 'helpers/accessors';
import { showHttpErrors } from 'helpers/errors';
import { downloadBlob, printBlob } from 'helpers/pdf';
import Log from 'helpers/log';

import { ReportModel } from './Models/ReportModel';
import { dateToString } from '../helpers/datetime';
import RootStore from './RootStore';

const defaultValue = 'Alle';

class ReportStore {
  @observable selectedFilter: ReportFilter = {};
  @observable sortConfig?: SortConfig = {
    accessor: 'title',
    desc: false,
  };
  @observable data: ReportModel = new ReportModel();
  @observable pdfBlobURL: any;
  @observable pdfBlob: any;
  @observable isLoading = false;

  @observable private _setBoth = true;
  @observable private _startDate = new Date();
  @observable private _endDate = new Date();
  @observable private _categories: Category[] = [];
  @observable private _secretaries: Secretary[] = [];

  @computed get period() {
    return [this._startDate, this._endDate];
  }

  @computed get customNumbers() {
    const customs = [
      ...new Set(this._secretaries.map(item => item.kostenStelleNr)),
    ].filter(item => item !== null);
    return [
      { label: defaultValue, value: '' },
      ...customs.map(el => ({ label: String(el), value: el })),
    ];
  }

  @computed get categories() {
    return this._categories.slice().sort(sortByAccessor(this.sortConfig));
  }

  @action.bound
  changeFilter(value, _, name) {
    this.selectedFilter[name] = value;
  }

  @action.bound
  async fetchData() {
    this.isLoading = true;

    try {
      const [{ data: report }, { data: secretaries }] = await Promise.all([
        httpFacade.report.reports(this.period, this.selectedFilter),
        httpFacade.user.secretaries(),
      ]);

      this.data = report;
      this._categories = report.categories;
      this._secretaries = secretaries as any;
    } catch (error) {
      Log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  onDateChange(date) {
    if (this._setBoth) {
      this._startDate = this._endDate = date;
    } else {
      if (Number(this._startDate) > Number(date)) {
        this._endDate = this._startDate;
        this._startDate = date;
      } else {
        this._endDate = date;
      }
    }

    this._setBoth = !this._setBoth;
  }

  @action.bound
  async fetchPDF(print?: boolean) {
    try {
      const { data } = await httpFacade.report.fetchReportPDF(
        this.period,
        this.selectedFilter,
      );

      const startDate = dateToString(this._startDate, '.', true);
      const endDate = dateToString(this._endDate, '.', true);
      const fileName = RootStore.localization.formatMessage('filename.report', {
        startDate,
        endDate,
      });

      const pdfBlobURL = URL.createObjectURL(data);
      const pdfBlob = new Blob([data], {
        type: 'application/pdf',
      });

      if (print) {
        printBlob({ fileName, pdfBlobURL, pdfBlob });
      } else {
        downloadBlob({ fileName, pdfBlobURL, pdfBlob });
      }
    } catch (error) {
      showHttpErrors(error);
    }
  }
}

export default ReportStore;
