import { CreateUpdateReservationDto } from 'http/Api/events';
import { EventReservation } from 'types/entities';

export function getReservationsData(
  reservations: EventReservation[],
): CreateUpdateReservationDto[] {
  return reservations.map(reservation => ({
    roomId: reservation.room.id,
    arrangementId: reservation.arrangement.id,
    menu: reservation.menu.map(menuItem => ({
      amount: menuItem.amount,
      id: menuItem.id,
      extra: menuItem.extra,
    })),
    cleaning: reservation.cleaning,
    preparation: reservation.preparation,
    description: reservation.description,
    participants: reservation.participants,
  }));
}
