import React from 'react';
import { observer } from 'mobx-react';

import style from './Planner.module.scss';

import classNames from 'classnames';

const EventCurrent = observer(() => {
  return <div className={classNames(style.eventCurrentWrapper)} />;
});

export default EventCurrent;
