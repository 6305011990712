import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './Description.module.scss';

import Slider from 'components/Slider/Slider';
import ProductCard from 'components/ProductCard/ProductCard';

import { ModalProps, DialogActionType } from 'stores/ModalStore';
import CateringStore from 'stores/CateringStore';
import RootStore from 'stores/RootStore';

interface Props extends ModalProps {
  store: CateringStore;
  initialSlide?: number;
}

@observer
class DescriptionModal extends Component<Props> {
  onClose = () => {
    this.props.onClose(DialogActionType.submit);
  };

  toggleProduct = id => () => {
    this.props.store.toggleProduct(id);
  };

  render() {
    const { store, initialSlide } = this.props;

    return (
      <Modal
        className={classNames(
          style.modal__description,
          RootStore.config.appSchema,
        )}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={this.onClose}
        visible
      >
        <Slider initialSlide={initialSlide}>
          {store.filteredProducts.map(product => (
            <div className={style.wrapper__description} key={product.id}>
              <ProductCard
                {...product}
                className={style.product}
                checked={store.checkedID.includes(product.id)}
                onClick={this.toggleProduct(product.id)}
              />

              <div className={style.wrapper__details}>
                <div className={style.detail}>
                  <h3 className={style.title}>
                    <FormattedMessage id="description" />
                  </h3>

                  <div
                    className={style.content}
                    dangerouslySetInnerHTML={{
                      __html: product.detailedDescription,
                    }}
                  />
                </div>

                {Boolean(product.allergens?.length) && (
                  <div className={style.detail}>
                    <h3 className={style.title}>
                      <FormattedMessage id="title.allergens" />
                    </h3>

                    {Boolean(product.allergens.length) && (
                      <ul className={style.allergens}>
                        {product.allergens.map(allergen => (
                          <li key={allergen.id}>
                            <img
                              className={style.allergen__icon}
                              src={allergen.icon}
                              alt="allergen"
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </Modal>
    );
  }
}

export default DescriptionModal;
