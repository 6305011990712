import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';
import classNames from 'classnames';

import RootStore from 'stores/RootStore';
import { ModalProps, DialogActionType } from 'stores/ModalStore';

import style from './AlertModal.module.scss';

interface Props extends ModalProps {
  message: string;
}

@observer
class AlertModal extends Component<Props> {
  lastActiveElement: HTMLElement = document.activeElement as HTMLElement;

  // Stay focus on activeElement
  componentDidMount() {
    if (this.lastActiveElement) {
      setTimeout(() => {
        this.lastActiveElement.focus();
      }, 0);
    }
  }

  onClose = () => {
    this.props.onClose(DialogActionType.submit);
  };

  render() {
    const { message } = this.props;

    return (
      <Modal
        className={classNames(style.modal, RootStore.config.appSchema)}
        wrapClassName={style.modal__wrap}
        mask={false}
        destroyOnClose={true}
        animation="fade"
        onClose={this.onClose}
        visible
      >
        <FormattedMessage id={message} />
      </Modal>
    );
  }
}

export default AlertModal;
