import React from 'react';
import { FormattedMessage } from 'react-intl';

import style from './EventPage.module.scss';

import Button from 'components/Button/Button';

import EventStore from 'stores/EventStore/EventStore';

const Controls = ({ event }: { event: EventStore }) => {
  return (
    <div className={style.controls}>
      {event.canEdit && (
        <Button
          className={style.btn}
          styleType="ghost"
          onClick={event.toEditPage}
        >
          <FormattedMessage id="button.edit" />
        </Button>
      )}
      {event.canCancel && (
        <Button
          className={style.btn}
          styleType="reject"
          onClick={event.cancelEvent}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
      )}
      {event.canDecline && (
        <Button
          className={style.btn}
          styleType="reject"
          onClick={event.declineEvent}
        >
          <FormattedMessage id="button.decline" />
        </Button>
      )}
      {event.canAccept && (
        <Button
          className={style.btn}
          styleType="accept"
          onClick={event.acceptEvent}
        >
          <FormattedMessage id="button.confirm" />
        </Button>
      )}
    </div>
  );
};

export default Controls;
