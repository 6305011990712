import React from 'react';
import classNames from 'classnames';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

import { MenuItem } from 'types/entities';

import style from './PanelProducts.module.scss';

interface Props {
  product: MenuItem;
  className?: string;
}

const SummaryProduct = (props: Props) => {
  const { product, className } = props;

  return (
    <div className={classNames(style.product__summary, 'grid-row', className)}>
      <div className="column">{product.title}</div>
      <div className="column">{product.amount}</div>

      <div className="column">
        <FormattedCurrency value={product.price} />
      </div>

      <div className="column">
        <FormattedCurrency value={product.price * product.amount} />
      </div>
    </div>
  );
};

export default SummaryProduct;
