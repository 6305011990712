import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isRequired(message = 'VM_IS_REQUIRED') {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];

        return value || value === 0
          ? undefined
          : { message, data: { name, value } };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
