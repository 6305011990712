import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  error: { key: string; params?: any };
}

export const ErrorMessages = (props: Props) => {
  return <FormattedMessage key={props.error.key} id={props.error.key} />;
};
