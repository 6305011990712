import RootStore from 'stores/RootStore';
import { dateAdd, getISODate } from 'helpers/datetime';

export const RC_HOLIDAY_CLASS_NAME = 'react-calendar-day--holiday';

export const tileDisabled = ({ date }) => {
  return (
    date.getDay() === 6 ||
    date.getDay() === 0 ||
    RootStore.config.publicHolidays.includes(
      getISODate(dateAdd(date, 1, 'day')),
    )
  );
};

export const tileHolidayClassName = date => {
  return tileDisabled(date) ? RC_HOLIDAY_CLASS_NAME : null;
};
