import React from 'react';
import classNames from 'classnames';

import style from './Planner.module.scss';

function TimeSlotWrapper(props) {
  // axis
  if (!props.resource) {
    const hour = props.value.getHours();
    const minutes = props.value.getMinutes();
    const showMinutes = !!minutes;
    const isDesiredMinutesInterval = minutes % 15 === 0;

    return (
      <div
        id={`timeSlot${hour}_${minutes}`}
        className={classNames(
          style.timeSlotWrapper,
          style.timeSlotWrapper__axis,
          showMinutes
            ? style.timeSlotWrapper__minutes
            : style.timeSlotWrapper__hours,
        )}
      >
        {showMinutes ? isDesiredMinutesInterval && minutes : hour}
      </div>
    );
  }
  return <div className={classNames(style.timeSlotWrapper)} />;
}

export default TimeSlotWrapper;
