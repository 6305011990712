import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import classNames from 'classnames';

import style from './Grid.module.scss';

import { Column, Types } from './types';
import { uniqueId } from 'helpers/uniqueId';
import { SortConfig } from 'types/entities';

import GridCaption from './GridCaption';
import Spinner from 'components/Spinner/Spinner';

interface Props<T> {
  loading?: boolean;
  config: Types<T>;
  data: T[];
  onSort?: (accessor) => void;
  onRowClick?: (row) => void;
  className?: string;
  activeRow?: T;
  defaultSorted?: {
    id: string;
    desc: boolean;
  };
  expandable?: boolean;
}

@observer
class Grid<T extends { id?: string; title?: string }> extends Component<
  Props<T>
> {
  @observable activeId;
  @observable sortingRule: SortConfig = {
    accessor: '',
    desc: false,
  };
  @observable expandable: boolean;
  @observable expandAll: boolean = false;

  @action
  onSortedChange = (column: Column) => () => {
    const { onSort } = this.props;

    if (column.sortable && onSort) {
      const desc = this.activeId === column.id && !this.sortingRule.desc;
      const { id, accessor } = column;

      this.activeId = id;
      this.sortingRule = { accessor: accessor || id, desc };

      onSort(this.sortingRule);
    }
  };

  @action
  onRowClick = row => () => {
    const { onRowClick } = this.props;

    if (onRowClick) {
      onRowClick(row);
    }
  };

  @action
  defaultSort = () => {
    const { onSort, config, defaultSorted } = this.props;

    if (defaultSorted) {
      const column = config.columns.find(it => it.id === defaultSorted.id);

      if (column && column.sortable && onSort) {
        const { id, accessor } = column;

        this.activeId = id;
        this.sortingRule = {
          accessor: accessor || id,
          desc: defaultSorted.desc,
        };

        onSort(this.sortingRule);
      }
    }
  };

  @action
  onExpandAllClicked = () => {
    this.expandAll = !this.expandAll;
  };

  componentDidMount() {
    this.defaultSort();
  }

  render() {
    const {
      data,
      config,
      className,
      onSort,
      loading = false,
      expandable = false,
    } = this.props;
    const { columns, item: GridItem } = config;

    return (
      <div className={classNames(style.grid, className)}>
        <div
          className={classNames('header', 'grid-row', {
            expandable_grid_row_header: expandable,
          })}
        >
          {expandable && (
            <div
              className={
                this.expandAll
                  ? 'expand__button__active'
                  : 'expand__button__inactive'
              }
              onClick={this.onExpandAllClicked}
            />
          )}
          {columns.map(caption => (
            <GridCaption
              caption={caption}
              key={caption.id}
              className={classNames({
                caption__active: this.activeId === caption.id,
                caption__sortable: caption.sortable && onSort,
                type__asc:
                  this.activeId === caption.id && !this.sortingRule.desc,
                type__desc:
                  this.activeId === caption.id && this.sortingRule.desc,
              })}
              onClick={this.onSortedChange(caption)}
            />
          ))}
        </div>

        <div className="body">
          {loading ? (
            <Spinner />
          ) : (
            data.map(row => (
              <GridItem
                item={row}
                key={row.id || uniqueId()}
                className={classNames({
                  item__active: row === this.props.activeRow,
                })}
                expandable={expandable}
                expanded={this.expandAll}
                onClick={this.onRowClick(row)}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

export default Grid;
