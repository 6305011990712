import { AxiosPromise, AxiosRequestConfig } from 'axios';

import { replaceUrlParams } from 'http/helpers';
import { instance } from './instance';

interface RequestConfig extends AxiosRequestConfig {
  replaceParams?: object;
}

function replaceParams(url: string, config?: RequestConfig): string {
  return config && config.replaceParams
    ? replaceUrlParams(url, config.replaceParams)
    : url;
}

const http = {
  get<T>(url: string, config?: RequestConfig): AxiosPromise<T> {
    return instance.get<T>(replaceParams(url, config), config || {});
  },

  put<T, S>(url: string, data: T, config?: RequestConfig): AxiosPromise<S> {
    return instance.put(replaceParams(url, config), data, config);
  },

  post<T, S>(url: string, data: T, config?: RequestConfig): AxiosPromise<S> {
    return instance.post(replaceParams(url, config), data, config);
  },

  patch<T, S>(url: string, data: T, config?: RequestConfig): AxiosPromise<S> {
    return instance.patch(replaceParams(url, config), data, config);
  },

  delete(url: string, config?: RequestConfig): AxiosPromise {
    return instance.delete(replaceParams(url, config), config);
  },
};

export default http;
