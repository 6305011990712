import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import ModalStore from 'stores/ModalStore';
import RootStore from 'stores/RootStore';
import Button from 'components/Button/Button';
import { ChangePasswordModal } from 'components/Modals/ChangePasswordModal/ChangePasswordModal';
import style from './UserProfile.module.scss';

@observer
class UserProfile extends Component {
  onPasswordChange = () => {
    ModalStore.showModal(ChangePasswordModal, {
      userId: RootStore.user.id,
    });
  };

  render() {
    return (
      <div className={style.wrapper}>
        <h1 className={style.title}>
          <FormattedMessage id="profile.title" />
        </h1>
        <div className={style.info}>
          <FormattedMessage id="profile.password" />
          <Button
            styleType={'text'}
            className={style.btn}
            onClick={this.onPasswordChange}
          >
            <FormattedMessage id="profile.password.change" />
          </Button>
        </div>
      </div>
    );
  }
}

export { UserProfile };
