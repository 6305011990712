import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';

import StepperStore from 'stores/Booking/StepperStore';

import style from './Navigation.module.scss';

interface Props {
  store: StepperStore;
  className?: string;
}

@observer
class Navigation extends Component<Props> {
  render() {
    const { store, className } = this.props;

    return (
      <div className={classNames(style.navigation, className)}>
        <Button onClick={store.previousStep} className={style.buttonArrow}>
          <Icon
            type="arrowLeft"
            className={classNames(style.icon, style.icon__arrow)}
          />
        </Button>

        <span className={style.bookingName}>
          <FormattedMessage id={store.currentStepTitle} />
        </span>
      </div>
    );
  }
}

export default Navigation;
