import { observable } from 'mobx';

import httpFacade from 'http/httpFacade';
import { BaseFormModel, isRequired, displayName, isEmail } from '../BaseForm';

export enum RecoveryFormStatus {
  loading = 'loading',
  pending = 'pending',
  success = 'success',
}

class RecoveryForm extends BaseFormModel {
  @observable
  @displayName('EMAIL')
  @isRequired()
  @isEmail()
  email = '';

  @observable message = '';
  @observable status = 'RecoveryFormStatus.pending';

  async send() {
    if (this.validate()) {
      try {
        const data = { email: this.email };

        this.message = '';
        this.status = RecoveryFormStatus.loading;

        await httpFacade.user.passwordRecovery(data);

        this.status = RecoveryFormStatus.success;
      } catch (error) {
        this.message = 'recovery.form.incorrect.email';
        this.status = RecoveryFormStatus.pending;
      }
    }
  }
}

export default RecoveryForm;
