import printJS from 'print-js';

const isIE11 = !!(window.navigator && window.navigator.msSaveOrOpenBlob);

export function printBlob({ fileName, pdfBlob, pdfBlobURL }) {
  isIE11
    ? window.navigator.msSaveOrOpenBlob(pdfBlob, `${fileName}.pdf`) // TODO: download instead print preview
    : printJS(pdfBlobURL); // http://printjs.crabbly.com/
}

export function downloadBlob({ fileName, pdfBlob, pdfBlobURL }) {
  if (isIE11) {
    window.navigator.msSaveOrOpenBlob(pdfBlob, `${fileName}.pdf`);
  } else {
    const anchor = document.createElement('a');

    document.body.appendChild(anchor);

    anchor.href = pdfBlobURL;
    anchor.download = fileName;

    anchor.click();
    anchor.remove();
  }
}
