import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PDFViewer from 'mgr-pdf-viewer-react';

import style from './InvoicePanel.module.scss';

import InvoiceGridStore from 'stores/InvoiceGridStore';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';

import { getRole, Roles } from 'helpers/roles';

import { InvoiceStatus } from 'types/entities';

interface Props {
  store: InvoiceGridStore;
  className?: string;
}

@observer
class InvoicePanel extends Component<Props> {
  accept = () =>
    this.props.store.activeItem &&
    this.props.store.accept(this.props.store.activeItem);

  approve = () =>
    this.props.store.activeItem &&
    this.props.store.approve(this.props.store.activeItem);

  decline = () =>
    this.props.store.activeItem &&
    this.props.store.decline(this.props.store.activeItem);

  getPDF = (print?: boolean) => () => {
    this.props.store.getPDF(print);
  };

  render() {
    const { store, className } = this.props;
    const isSecretary = getRole(Roles.SECRETARY);
    const isManager = getRole(Roles.CATERER_MANAGER);
    const isMasterBooking = getRole(Roles.MASTER_BOOKING);

    return (
      <div className={classNames(style.panel, className)}>
        {!store.pdfBlobURL && 0 ? null : (
          <>
            <div className={classNames(style.controls, 'row controls')}>
              <div className="row">
                <Button className={style.btn__pdf} onClick={this.getPDF(true)}>
                  <Icon type="printer" className={style.icon} />
                </Button>

                <Button className={style.btn__pdf} onClick={this.getPDF()}>
                  <Icon type="pdf" className={style.icon} />
                </Button>
              </div>

              <div>
                {(isSecretary || isMasterBooking) &&
                  store.activeItem &&
                  store.activeItem.status === InvoiceStatus.inApproval && (
                    <Button
                      styleType="reject"
                      className={style.btn}
                      onClick={this.decline}
                    >
                      <FormattedMessage id="button.decline" />
                    </Button>
                  )}

                {(isSecretary || isMasterBooking) &&
                  store.activeItem &&
                  store.activeItem.status === InvoiceStatus.inApproval && (
                    <Button
                      styleType="accept"
                      className={style.btn}
                      onClick={this.accept}
                    >
                      <FormattedMessage id="button.confirm" />
                    </Button>
                  )}

                {isManager &&
                  store.activeItem &&
                  (store.activeItem.status === InvoiceStatus.draft ||
                    store.activeItem.status === InvoiceStatus.declined) && (
                    <Button
                      styleType="accept"
                      className={style.btn}
                      onClick={this.approve}
                    >
                      <FormattedMessage id="button.approve" />
                    </Button>
                  )}
              </div>
            </div>

            {store.activeItem && store.activeItem.comment && (
              <div className={style.comment}>
                <h4 className={style.commentTitle}>
                  <FormattedMessage id="state.rejected" />
                </h4>

                <p className={style.comment__text}>
                  {store.activeItem.comment}
                </p>
              </div>
            )}

            {store.pdfBlobURL && (
              <div className={style.wrapper__pdf}>
                <PDFViewer
                  document={{
                    url: store.pdfBlobURL,
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default InvoicePanel;
