import { action, observable } from 'mobx';
import ArrangementModel from 'stores/Models/ArrangementModel';
import RoomModel, { RoomDataType } from 'stores/Models/RoomModel';
import { MenuItem, EventReservation, Room } from 'types/entities';

interface IEventReservationData {
  reservationData?: EventReservation;
  roomData?: Pick<Room, RoomDataType>;
}

export class EventReservationModel implements EventReservation {
  @observable id: string = '';
  @observable.shallow arrangement = new ArrangementModel();
  @observable menu: MenuItem[] = [];
  @observable cleaning: number = 0;
  @observable preparation: number = 0;
  @observable description: string = '';
  @observable participants: string = '';
  @observable room: RoomModel = new RoomModel();
  @observable roomPrice = 0;

  constructor({ reservationData, roomData }: IEventReservationData) {
    if (roomData) {
      this.room.update(roomData);
      this.cleaning = roomData.cleaning;
      this.preparation = roomData.preparation;
    }
    if (reservationData) {
      this.update(reservationData);
    }
  }

  @action.bound
  update(data: EventReservation) {
    this.id = data.id;
    this.arrangement = data.arrangement;
    this.menu = data.menu;
    this.cleaning = data.cleaning;
    this.preparation = data.preparation;
    this.description = data.description;
    this.participants = data.participants;
    this.room = new RoomModel(data.room);
    this.roomPrice = data.roomPrice ?? 0;
  }
}
