import React from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/routes';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import RecoverFormModel, {
  RecoveryFormStatus,
} from 'stores/Forms/RecoveryForm';
import RootStore from 'stores/RootStore';

import Button from 'components/Button/Button';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/TextField/TextField';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';

import style from './Login.module.scss';

@observer
class RecoverPage extends React.Component {
  recoverForm = new RecoverFormModel();
  FormControl = bindFormControl(this.recoverForm);

  submit = async event => {
    event.preventDefault();

    await this.recoverForm.send();
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <section className={style.layout}>
        {this.recoverForm.status === RecoveryFormStatus.loading && (
          <Spinner contain />
        )}
        <div className={style.wrapper__side}>
          <Icon type={RootStore.config.appSchema} className={style.logo} />
        </div>
        <div className={classNames(style.wrapper__side, style.wrapper__left)}>
          <div className={style.wrapper__form}>
            {this.recoverForm.status === RecoveryFormStatus.success ? (
              <div className={style.recover__successMessage}>
                <p>
                  <FormattedMessage id="recovery.form.success" />
                </p>
                <p>
                  <FormattedMessage id="recovery.form.success2" />
                </p>
              </div>
            ) : (
              <form
                onSubmit={this.submit}
                name="recover-form"
                className={style.form}
              >
                <h3 className={style.title}>
                  <FormattedMessage id="page.recover.title" />
                </h3>
                <div className={style.recover__text}>
                  <FormattedMessage id="page.recover.text" />
                </div>
                {this.recoverForm.message && (
                  <div className={style.message}>
                    <FormattedMessage id={this.recoverForm.message} />
                  </div>
                )}
                <div className={style.controls__form}>
                  <FormControl
                    name="email"
                    className={style.control}
                    render={props => <TextField {...props} />}
                  />
                </div>
                <div className={style.wrapper__btn}>
                  <Button
                    styleType={'accept'}
                    type="submit"
                    className={style.btn__recover}
                  >
                    <FormattedMessage id="button.recover" />
                  </Button>
                </div>
              </form>
            )}
            <div className={style.link__wrapper}>
              <div className={style.link__note}>
                <FormattedMessage id="page.recover.back.note" />
              </div>
              <NavLink to={ROUTES.login} className={style.link}>
                <FormattedMessage id="page.recover.back.link" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RecoverPage;
