import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedNumber } from 'react-intl';
import { observer } from 'mobx-react';

import style from './Statistic.module.scss';

export interface StatisticData {
  title?: React.ReactNode;
  values: Array<{ name: React.ReactNode; value: number }>;
}

interface Props {
  data: StatisticData;
  className?: string;
  schema?: 'color' | 'default';
}

@observer
class Statistic extends Component<Props> {
  render() {
    const { data, className, schema = 'default' } = this.props;

    return (
      <div
        className={classNames(
          style.content,
          {
            [style.schema__color]: schema === 'color',
          },
          className,
        )}
      >
        <h3 className={style.title}>{data.title}</h3>

        <ul className={style.data}>
          {data.values.map((item, index) => (
            <li className={style.row} key={index}>
              <span className={style.name}>{item.name}</span>
              <span className={style.value}>
                {item.value === undefined ? (
                  '-'
                ) : (
                  <FormattedNumber value={item.value} />
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Statistic;
