import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import classNames from 'classnames';

import ModalStore from 'stores/ModalStore';
import { EventReservationModel } from 'stores/Models/EventReservationModel';

import { Field } from 'components/Form/Field/Field';
import Button from 'components/Button/Button';
import { InfoModal } from 'components/Modals/InfoModal/InfoModal';
import Icon from 'components/Icon/Icon';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import { RoomRow } from 'pages/BookingPage/RoomRow/RoomRow';
import { isParticipantsRequired } from 'pages/BookingPage/Summary';

import style from './RoomSummary.module.scss';

interface Props extends WrappedComponentProps {
  reservation: EventReservationModel;
  roomPrice: number;
  showValidationErrors: boolean;
}

@observer
class RoomSummary extends Component<Props> {
  textarea: React.RefObject<HTMLTextAreaElement> = React.createRef();
  participantsRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

  onCommentChange = ({ target }) => {
    const { reservation } = this.props;
    const { name, value } = target;

    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;

    reservation[name] = value;
  };

  onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  openInfoModal = () => {
    ModalStore.showModal(InfoModal, {
      message: <FormattedMessage id="booking.summary.info" />,
    });
  };

  componentDidMount() {
    const { current } = this.textarea;

    if (current) {
      current.style.height = `${current.scrollHeight}px`;
    }

    if (this.participantsRef.current) {
      this.participantsRef.current.style.height = `${this.participantsRef.current.scrollHeight}px`;
    }
  }

  render() {
    const { reservation, roomPrice, intl, showValidationErrors } = this.props;
    const { menu, room } = reservation;
    const showParticipantsValidationError =
      showValidationErrors &&
      isParticipantsRequired(reservation) &&
      !reservation.participants;

    return (
      <div className={style.summary__roomInfo}>
        <div className={style.summary__row}>
          <RoomRow
            type="info"
            roomData={{
              title: room.title,
              arrangement: reservation.arrangement.title,
            }}
            className={style.roomRow}
          >
            <Button
              styleType="text"
              className={style.btnInfo}
              onClick={this.openInfoModal}
            >
              <Icon type="info" />
            </Button>
          </RoomRow>

          <div className={style.summary__totalValue}>
            <FormattedCurrency value={roomPrice} />
          </div>
        </div>

        <div className={style.summary__row}>
          <Icon type="users" className={style.summary__icon} />
          <Field.Wrapper>
            <textarea
              id="participants"
              name="participants"
              maxLength={10000}
              className={classNames(style.textarea, {
                [style.textareaError]: showParticipantsValidationError,
              })}
              value={reservation.participants}
              onChange={this.onCommentChange}
              rows={1}
              placeholder={intl.formatMessage({
                id: 'event.input.participants.placeholder',
              })}
              ref={this.textarea}
            />
            <Field.Error
              show={showParticipantsValidationError}
              messageId="event.input.participants.placeholder"
            />
          </Field.Wrapper>
        </div>
        <div className={style.summary__row}>
          <Icon type="message" className={style.summary__icon} />
          <textarea
            id="description"
            name="description"
            maxLength={10000}
            className={style.textarea}
            value={reservation.description}
            onChange={this.onCommentChange}
            onKeyDown={this.onKeyDown}
            rows={1}
            placeholder={intl.formatMessage({
              id: 'event.input.description.placeholder',
            })}
            ref={this.textarea}
          />
        </div>

        {!!menu.length && (
          <>
            <div
              className={classNames(style.summary__row, style.row__catering)}
            >
              <Icon type="catering" className={style.summary__icon} />
              <div>
                <FormattedMessage id="title.catering" />
              </div>
            </div>

            <div className={style.summary__row}>
              {menu.map(item => (
                <div key={item.id} className={style.summary__product}>
                  <div className={style.summary__productInfo}>
                    <div className={style.summary__productInfoCell}>
                      <span>{item.title}</span>
                    </div>
                    <div className={style.summary__productInfoCell}>
                      <FormattedCurrency value={item.price} />
                    </div>
                    <div className={style.summary__productInfoCell}>
                      {item.amount}
                    </div>
                  </div>
                  <div className={style.summary__totalValue}>
                    <FormattedCurrency value={item.price * item.amount} />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default injectIntl(RoomSummary);
