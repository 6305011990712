import { action, computed, observable, runInAction } from 'mobx';
import {
  BaseFormModel,
  displayName,
  isEmail,
  isNumber,
  isRegEx,
  isRequired,
} from '../BaseForm';
import { UserPriceGroup, UserRole } from 'types/entities';
import { IUserCreateDto } from 'http/Api/users';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import { Roles } from 'helpers/roles';
import { REG_EXP_ONLY_LETTERS } from 'stores/BaseForm/validators/isRegEx';
import RootStore from 'stores/RootStore';

const DEFAULT_KOSTENSTELLE_NR = '0';
const STAFF_ROLE_POSTFIX = 'STAFF';

const isStaffRole = (role: UserRole): boolean => {
  return role.name.includes(STAFF_ROLE_POSTFIX);
};

const getStaffRolesIds = (roles: UserRole[]): string[] => {
  return roles.filter(isStaffRole).map(it => it.id);
};

export interface ICreateUserFormData {
  id?: string;
  userRole?: Roles;
}

class UserForm extends BaseFormModel {
  @observable roles: UserRole[] = [];
  id: string = '';

  @observable
  @displayName('title.firstName')
  @isRequired()
  firstName = '';

  @observable
  @displayName('title.lastName')
  @isRequired()
  lastName = '';

  @observable
  @displayName('title.email')
  @isEmail()
  @isRequired()
  email = '';

  @observable
  @displayName('title.nickname')
  nickname = '';

  @observable
  @displayName('title.role')
  @isRequired()
  roleId = '';

  @observable
  @displayName('title.priceGroup')
  @isRequired()
  priceGroup: UserPriceGroup | '' = '';

  @observable
  @displayName('title.kostenstelle')
  kostenStelleNr: string = '';

  @observable
  @displayName('title.company')
  company = '';

  @observable
  @displayName('title.orderer')
  department = '';

  @observable
  @displayName('title.country')
  @isRegEx(REG_EXP_ONLY_LETTERS, 'VM_IS_LETTER')
  country = '';

  @observable
  @displayName('title.town')
  @isRegEx(REG_EXP_ONLY_LETTERS, 'VM_IS_LETTER')
  town = '';

  @observable
  @displayName('title.streetAndHouseNumber')
  streetAndHouseNumber = '';

  @observable
  @displayName('title.postCode')
  @isNumber()
  postCode = '';

  @observable
  @displayName('title.additionalAddress')
  additionalAddress = '';

  private readonly userRole: Roles;

  constructor({ id, userRole }: ICreateUserFormData) {
    super();

    if (id) {
      this.id = id;
    }

    if (userRole) {
      this.userRole = userRole;
    }
  }

  @computed
  get isSelf(): boolean {
    return this.id === RootStore.user.id;
  }

  @computed
  get selectedRole(): UserRole {
    return this.roles.find(role => role.id === this.roleId) as UserRole;
  }

  @computed
  get isSecretary(): boolean {
    return (
      this.roles.find(role => role.id === this.roleId)?.name ===
      Roles.SECRETARY.replace('ROLE_', '')
    );
  }

  @computed
  get hasAddress(): boolean {
    return !!(
      this.additionalAddress ||
      this.country ||
      this.postCode ||
      this.streetAndHouseNumber ||
      this.town
    );
  }

  @computed
  get userData(): IUserCreateDto {
    return {
      ...(this.hasAddress
        ? {
            address: {
              ...(this.additionalAddress
                ? { additionalAddress: this.additionalAddress }
                : {}),
              ...(this.country ? { country: this.country } : {}),
              ...(this.postCode ? { postCode: this.postCode } : {}),
              ...(this.streetAndHouseNumber
                ? { street: this.streetAndHouseNumber }
                : {}),
              ...(this.town ? { town: this.town } : {}),
            },
          }
        : {}),
      ...(this.company ? { company: this.company } : {}),
      ...(this.department ? { department: this.department } : {}),
      email: this.email,
      firstName: this.firstName,
      kostenStelleNr: this.kostenStelleNr
        ? this.kostenStelleNr
        : DEFAULT_KOSTENSTELLE_NR,
      lastName: this.lastName,
      ...(this.nickname ? { nickname: this.nickname } : {}),
      priceGroup: this.priceGroup as UserPriceGroup,
      roleIds: isStaffRole(this.selectedRole)
        ? getStaffRolesIds(this.roles)
        : [this.roleId],
    };
  }

  @action.bound
  setDefaultUserRole() {
    this.roleId =
      this.roles.find(role => role.name === this.userRole)?.id ?? '';
  }

  @action.bound
  setPriceGroup(value) {
    this.priceGroup = value;
  }

  @action.bound
  setUserRoleId(value) {
    this.roleId = value;
  }

  @action.bound
  async init() {
    try {
      await this.fetchUserRoles();
      this.setDefaultUserRole();
      if (this.id) {
        await this.fetchUser();
      }
    } catch (error) {
      Log.warn(error);
    }
  }

  @action.bound
  async fetchUserRoles() {
    try {
      const { data } = await httpFacade.users.fetchRoles();

      this.roles = data;
    } catch (error) {
      Log.warn(error);
    }
  }

  @action.bound
  async fetchUser() {
    try {
      const { data } = await httpFacade.users.fetchUser(this.id);

      runInAction(() => {
        this.roleId = data.roles[0].id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.nickname = data.nickname ?? '';
        this.priceGroup = data.priceGroup ?? '';
        this.kostenStelleNr =
          data.kostenStelleNr !== null && data.kostenStelleNr !== undefined
            ? data.kostenStelleNr
            : '';
        this.company = data.company ?? '';
        this.department = data.department ?? '';
        this.country = data.address?.country ?? '';
        this.town = data.address?.town ?? '';
        this.streetAndHouseNumber = data.address?.street ?? '';
        this.postCode = data.address?.postCode ?? '';
        this.additionalAddress = data.address?.additionalAddress ?? '';
      });
    } catch (error) {
      Log.warn(error);
    }
  }
}

export default UserForm;
