import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedDate, FormattedTime } from 'react-intl';
import { observable } from 'mobx';

import style from './AppDate.module.scss';

interface Props {
  withTime?: boolean;
  className?: string;
}

@observer
class AppDate extends Component<Props> {
  @observable data = new Date();
  @observable interval;

  componentDidMount() {
    this.interval = setInterval(() => (this.data = new Date()), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { className, withTime } = this.props;

    return (
      <div className={classNames(style.content, className)}>
        <FormattedDate
          value={this.data}
          day="2-digit"
          month="long"
          year="numeric"
          children={txt => <span className={style.date}>{txt}</span>}
        />

        {withTime && (
          <FormattedTime
            value={this.data}
            children={txt => <span className={style.time}>{txt}</span>}
          />
        )}
      </div>
    );
  }
}

export default AppDate;
