export enum ResponseErrMessage {
  AUTH_TOKEN_EXPIRED_ERROR = 'error.business.security.authToken.expired',
  REFRESH_TOKEN_EXPIRED_ERROR = 'error.business.security.refreshToken.expired',
}

export interface ResponseError {
  message: ResponseErrMessage;
  params: string[];
}

export interface TokenErrorsResponse {
  errors: ResponseError[];
}

export interface UpdateData {
  firstName: string;
  lastName: string;
  nickname: string;
}
