import http from '../http';

import { dateToString, stringToDateTime } from 'helpers/datetime';
import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import {
  Arrangement,
  CanEditCateringType,
  CurrencyValue,
  EventStatus,
  MenuItem,
  Room,
  UserPriceGroup,
} from 'types/entities';
import { getRole, Roles } from 'helpers/roles';

interface Event {
  createdById: string;
  canBeRejected: boolean;
  canEdit: boolean;
  canEditCatering: CanEditCateringType;
  cleaning: number;
  comment?: string;
  duplicated: boolean;
  duration: number;
  id: string;
  description: string;
  invoice?: {
    id: string;
    number: string;
    status: string;
  };
  personAmount: number;
  preparation: number;
  priceGroupBooking: UserPriceGroup;
  reservations: Array<{
    arrangement: Arrangement;
    cleaning: number;
    description: string;
    id: string;
    menu: MenuItem[];
    participants: string;
    preparation: number;
    room: Room;
  }>;
  roomPrice: CurrencyValue;
  roomServiceCost: CurrencyValue;
  start: string;
  status: EventStatus;
  totalPrice: CurrencyValue;
  title: string;
  version: number;
  nextGenId?: string;
}

type FetchEventsResponse = Event[];
type FetchEventResponse = Event;

export interface CreateUpdateReservationDto {
  arrangementId: string;
  cleaning: number;
  description: string;
  menu: Array<{
    amount: number;
    id: string;
    extra: boolean;
  }>;
  participants: string;
  preparation: number;
  roomId: string;
}

export interface CreateEventRequestData {
  duration: number;
  personAmount: number;
  reservations: CreateUpdateReservationDto[];
  start: string;
  title: string;
}

export type EditEventRequestData = CreateEventRequestData &
  ChangeStatusRequestBody;

interface ChangeStatusRequestBody {
  version: number;
}

export interface EditExtraMenuRequestData extends ChangeStatusRequestBody {
  reservations: Array<{
    roomId: string;
    menu: Array<{
      amount: number;
      id: string;
      extra: boolean;
    }>;
  }>;
  version: number;
}

interface DeclineEventRequestBody extends ChangeStatusRequestBody {
  comment: string;
}

export const eventsApi = {
  fetchEvents(startDate: Date) {
    const startDateString = dateToString(startDate);

    return http
      .get<FetchEventsResponse>(appUrl(URLS.events), {
        params: {
          startDate: startDateString,
        },
      })
      .then(response => ({
        ...response,
        data: response.data.map(event => ({
          ...event,
          start: stringToDateTime(event.start),
        })),
      }));
  },

  fetchEventsForPeriod(startDate: Date, endDate: Date) {
    const startDateString = dateToString(startDate);
    const endDateString = dateToString(endDate);

    return http
      .get<FetchEventsResponse>(appUrl(URLS.eventsForPeriod), {
        params: {
          startDate: startDateString,
          endDate: endDateString,
        },
      })
      .then(response => ({
        ...response,
        data: response.data.map(event => ({
          ...event,
          start: stringToDateTime(event.start),
        })),
      }));
  },

  fetchSchedulerEvents(startDate: Date) {
    const startDateString = dateToString(startDate);

    return http
      .get<FetchEventsResponse>(appUrl(URLS.eventsScheduler), {
        params: {
          startDate: startDateString,
        },
      })
      .then(response => ({
        ...response,
        data: response.data.map(event => ({
          ...event,
          start: stringToDateTime(event.start),
        })),
      }));
  },

  fetchEventById(id: string) {
    return http
      .get<FetchEventResponse>(appUrl(URLS.eventById), {
        replaceParams: {
          id,
        },
      })
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          start: stringToDateTime(response.data.start),
          reservations: response.data.reservations.map(reservation => ({
            ...reservation,
            menu: reservation.menu.map(menuItem => ({
              ...menuItem,
              detailedDescription: Array.isArray(menuItem.detailedDescription)
                ? menuItem.detailedDescription
                : [],
            })),
          })),
        },
      }));
  },

  createEvent(data: CreateEventRequestData, ownerId?: string) {
    return http.post<CreateEventRequestData, Event>(appUrl(URLS.events), data, {
      params: {
        ...(ownerId ? { ownerId } : {}),
        nextGen: true,
      },
    });
  },

  editEvent(id: string, data: EditEventRequestData, ownerId?: string) {
    return http.put<EditEventRequestData, Event>(appUrl(URLS.eventById), data, {
      replaceParams: { id },
      params: {
        ...(ownerId && getRole(Roles.MASTER_BOOKING) ? { ownerId } : {}),
      },
    });
  },

  editExtraMenu(id: string, data: EditExtraMenuRequestData) {
    return http.put<EditExtraMenuRequestData, unknown>(
      appUrl(URLS.eventExtraMenu),
      data,
      { replaceParams: { id } },
    );
  },

  acceptEvent(id: string, version: number) {
    return http.put<ChangeStatusRequestBody, unknown>(
      appUrl(URLS.eventAccept),
      { version },
      {
        replaceParams: { id },
      },
    );
  },

  approveEvent(id: string, version: number) {
    return http.put<ChangeStatusRequestBody, unknown>(
      appUrl(URLS.eventApprove),
      { version },
      {
        replaceParams: { id },
      },
    );
  },

  cancelEvent(id: string, version: number, ownerId?: string) {
    return http.put<ChangeStatusRequestBody, unknown>(
      appUrl(URLS.eventCancel),
      { version },
      {
        replaceParams: { id },
        params: {
          ...(ownerId && getRole(Roles.MASTER_BOOKING) ? { ownerId } : {}),
        },
      },
    );
  },

  declineEvent(id: string, comment: string, version: number) {
    return http.put<DeclineEventRequestBody, unknown>(
      appUrl(URLS.eventDecline),
      { version, comment },
      {
        replaceParams: { id },
      },
    );
  },
};
