/* eslint-disable react/style-prop-object */
import React from 'react';
import { FormattedNumber, FormattedNumberParts } from 'react-intl';

interface Props {
  value: number;
  withSymbol?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

const FormattedCurrency = ({
  value,
  withSymbol = true,
  ...otherProps
}: Props) => {
  const floatValue = value / 100;

  return (
    <>
      {withSymbol ? (
        <FormattedNumber
          value={floatValue}
          style="currency"
          currency="EUR"
          {...otherProps}
        />
      ) : (
        <FormattedNumberParts
          value={floatValue}
          style="currency"
          currency="EUR"
          {...otherProps}
        >
          {parts => (
            <>
              {parts.slice(0, -1).reduce((acc, part) => acc + part.value, '')}
            </>
          )}
        </FormattedNumberParts>
      )}
    </>
  );
};

export default FormattedCurrency;
