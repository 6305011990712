import RootStore from 'stores/RootStore';
import { ROUTES } from 'routes/routes';

export enum Roles {
  SECRETARY = 'ROLE_SECRETARY',
  CATERER_MANAGER = 'ROLE_CATERER_MANAGER',
  CATERER_STAFF = 'ROLE_CATERER_STAFF',
  ADMIN = 'ROLE_ADMIN',
  MASTER_BOOKING = 'ROLE_MASTER_BOOKING',
}

export function getRole(role: Roles) {
  return RootStore.user.roles.includes(role);
}

export function getDefaultRouteByRole() {
  if (getRole(Roles.ADMIN)) {
    return ROUTES.users;
  } else if (getRole(Roles.CATERER_MANAGER)) {
    return ROUTES.manager;
  } else if (getRole(Roles.CATERER_STAFF)) {
    return ROUTES.staffDashboard;
  } else if (getRole(Roles.SECRETARY)) {
    return ROUTES.dashboard;
  } else if (getRole(Roles.MASTER_BOOKING)) {
    return ROUTES.bookingNew;
  } else {
    return ROUTES.menuEvents;
  }
}
