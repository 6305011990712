import { action, computed, observable, reaction } from 'mobx';

import Log from 'helpers/log';
import { showHttpErrors } from 'helpers/errors';
import httpFacade from 'http/httpFacade';
import { EditExtraMenuRequestData } from 'http/Api/events';

import {
  MenuSet,
  MenuItemType,
  CanEditCateringType,
  MenuCategory,
  MenuItem,
} from 'types/entities';
import EventModel from 'stores/Models/EventModel';

import ModalStore from './ModalStore';
import DescriptionModal from 'components/Modals/DescriptionModal/DescriptionModal';

function getFilteredMenu(menu: MenuItem[], extra = true): MenuItem[] {
  return menu.filter(menuItem => menuItem.extra === extra);
}

function getMenuTotalPrice(menu: MenuItem[]): number {
  return menu.reduce(
    (result, product) => result + product.price * product.amount,
    0,
  );
}

class CateringStore {
  @observable pending = false;
  @observable products: MenuSet[] = [];
  @observable categories: MenuCategory[] = [];
  @observable filter = '';
  @observable showTermError = false;

  private readonly extra: boolean;
  private event: EventModel;
  private initialExtraMenuLength: number;

  @computed
  get canSaveExtraMenu(): boolean {
    return !!this.initialExtraMenuLength || !!this.extraMenu.length;
  }

  @computed
  get menu(): MenuItem[] {
    return this.event.selectedReservation?.menu ?? [];
  }

  set menu(menu) {
    this.event.selectedReservation.menu = menu;
  }

  @computed
  get mainMenu() {
    return getFilteredMenu(this.menu, false);
  }

  @computed
  get extraMenu() {
    return getFilteredMenu(this.menu);
  }

  @computed
  get filteredProducts() {
    return this.products.filter(
      product => product.category.title === this.filter,
    );
  }

  @computed
  get totalPrice() {
    return getMenuTotalPrice(this.menu);
  }

  @computed
  get extraMenuPrice() {
    return getMenuTotalPrice(this.extraMenu);
  }

  @computed
  get productTypes() {
    return this.categories.map(category => category.title);
  }

  @computed
  get checkedID() {
    return getFilteredMenu(this.menu, this.extra).map(item => item.id);
  }

  constructor(event: EventModel, extra = false) {
    this.event = event;
    this.extra = extra;

    reaction(
      () => this.showTermError,
      value =>
        value ? setTimeout(() => (this.showTermError = false), 5000) : null,
    );
  }

  @action.bound
  async saveAdditionalMenu(roomId: string) {
    this.pending = true;
    const extraMenuData: EditExtraMenuRequestData = {
      reservations: this.event.reservations.map(reservation => {
        return {
          roomId: reservation.room.id,
          menu:
            roomId === reservation.room.id
              ? this.extraMenu.map(item => ({
                  amount: item.amount,
                  extra: item.extra,
                  id: item.id,
                }))
              : getFilteredMenu(reservation.menu),
        };
      }),
      version: this.event.version ?? 0,
    };

    try {
      await httpFacade.events.editExtraMenu(this.event.id, extraMenuData);
    } catch (error) {
      showHttpErrors(error);
    }

    this.pending = false;
  }

  @action.bound
  setActiveFilter(filter) {
    this.filter = filter;
  }

  @action
  async fetchMenu(defaultMenuId?: string) {
    try {
      const [products, categories] = await Promise.all([
        httpFacade.menu.fetchMenu(this.event.priceGroupBooking, this.extra),
        httpFacade.menu.fetchMenuCategories(),
      ]);

      this.products = products.data;
      this.categories = categories.data;
      this.setActiveFilter(this.productTypes[0]);

      if (defaultMenuId) {
        this.toggleProduct(defaultMenuId);
      }
      if (this.extra) {
        this.initialExtraMenuLength = this.extraMenu.length;
      }
    } catch (error) {
      Log.warn(error);
    }
  }

  @action.bound
  async showDetails(productID?: string) {
    const index = this.filteredProducts.findIndex(
      product => product.id === productID,
    );

    await ModalStore.showModal(DescriptionModal, {
      store: this,
      initialSlide: index,
    });
  }

  @action.bound
  toggleProduct(id: string) {
    const product = this.products.find(item => item.id === id);

    if (product && !this.canEditProduct(product)) {
      this.showTermError = true;
    } else {
      const isChecked = this.menu.some(
        item => item.id === id && item.extra === this.extra,
      );

      if (isChecked) {
        this.menu = this.menu.filter(
          item => item.id !== id || item.extra !== this.extra,
        );
      } else {
        this.menu.push({ ...product!, amount: 1, extra: this.extra });
      }
    }
  }

  @action.bound
  increaseProductAmount = product => () => {
    if (product && !this.canEditProduct(product)) {
      this.showTermError = true;
    } else {
      ++product.amount;
    }
  };

  @action.bound
  reduceProductAmount = product => () => {
    if (product && !this.canEditProduct(product)) {
      this.showTermError = true;
    } else {
      product.amount = Math.max(--product.amount, 1);
    }
  };

  @action.bound
  canEditProduct(product: MenuSet) {
    return (
      this.event.canEditCatering === CanEditCateringType.all ||
      (product.category.type === MenuItemType.Drink &&
        this.event.canEditCatering === CanEditCateringType.drink)
    );
  }
}

export default CateringStore;
