import { action, computed, observable, reaction } from 'mobx';

import { ROUTES } from 'routes/routes';
import httpFacade from 'http/httpFacade';
import { showHttpErrors } from 'helpers/errors';
import Log from 'helpers/log';

import EventStore from 'stores/EventStore/EventStore';
import ModalStore from 'stores/ModalStore';
import AppRouter from 'stores/AppRouter';
import RoomModel from 'stores/Models/RoomModel';
import EventModel from 'stores/Models/EventModel';
import CateringStore from 'stores/CateringStore';
import StepperStore, { StepStore } from './StepperStore';
import RoomSelectionStore from './RoomSelectionStore';
import SeatingStore from './SeatingStore';

import ConfirmModal from 'components/Modals/ConfirmModal/ConfirmModal';
import PreventModal from 'components/Modals/PreventModal/PreventModal';
import { replaceUrlParams } from '../../http/helpers';

class RoomSelectionStep extends RoomSelectionStore implements StepStore {
  title = 'booking.step.newBooking.title';

  @computed
  get valid() {
    return (
      !!this.bookingStore.event.start &&
      !!this.bookingStore.event.reservations.length
    );
  }
}

class SeatingStep extends SeatingStore implements StepStore {
  title = 'booking.step.setup.title';

  @computed
  get valid() {
    return this.isArrangementsChecked;
  }
}

const SummaryStep: StepStore = {
  title: 'booking.step.summary.title',
  valid: true,
};

export const PERCENTAGE_OF_SERVICE_COST = 0.05;

export class BookingStore extends EventStore {
  @observable pending = false;

  @observable.shallow selectRoom: RoomSelectionStep;
  @observable.shallow seating: SeatingStep;
  @observable.shallow catering: CateringStore;

  @observable stepper: StepperStore;

  readonly eventID?: string;
  readonly menuID?: string;
  readonly startDate?: Date;

  @observable rooms: RoomModel[] = [];

  @computed
  get maxCleaningDuration(): number {
    const cleaningDurations = this.rooms.map(room => room.cleaning);
    return Math.max(0, ...cleaningDurations);
  }

  @computed
  get maxPreparationDuration(): number {
    const preparationDurations = this.rooms.map(room => room.preparation);
    return Math.max(0, ...preparationDurations);
  }

  @computed
  get minPreparationDuration(): number {
    const preparationDurations = this.rooms.map(room => room.preparation);
    return Math.min(...preparationDurations);
  }

  constructor({
    id,
    menuId,
    date,
  }: {
    id?: string;
    menuId?: string;
    date?: Date;
  }) {
    super();

    this.event = new EventModel();
    this.eventID = id;
    this.menuID = menuId;
    this.startDate = date;

    this.selectRoom = new RoomSelectionStep(this);
    this.seating = new SeatingStep(this);
    this.catering = new CateringStore(this.event);

    this.stepper = new StepperStore([
      this.selectRoom,
      this.seating,
      SummaryStep,
    ]);
  }

  @action.bound
  async init() {
    if (this.eventID) {
      await this.fetchEvent(this.eventID);
    }
    await this.fetchRooms();
    await this.selectRoom.init(this.startDate);

    reaction(
      () => this.event.owner.id,
      () => {
        this.fetchRooms();
      },
    );
  }

  @computed
  get totalPrice() {
    return this.roomsPrice + this.roomsServiceCost + this.cateringTotalPrice;
  }

  @computed
  get roomsPrice(): number {
    return this.event.reservations.reduce((sum, reservation) => {
      const roomModel = this.rooms.find(
        room => room.id === reservation.room.id,
      );
      const roomPrice = roomModel ? roomModel.price(this.event.duration) : 0;
      return sum + roomPrice;
    }, 0);
  }

  @computed
  get roomsServiceCost(): number {
    return this.roomsPrice * PERCENTAGE_OF_SERVICE_COST;
  }

  @computed
  get cateringTotalPrice(): number {
    return this.event.reservations.reduce((sum, reservation) => {
      const reservationCateringSum = reservation.menu.reduce(
        (resSum, menuItem) => resSum + menuItem.amount * menuItem.price,
        0,
      );
      return sum + reservationCateringSum;
    }, 0);
  }

  @computed
  get hasCatering() {
    return (
      !!this.catering &&
      this.event.reservations.some(reservation => !!reservation.menu.length)
    );
  }

  @action
  async fetchRooms() {
    try {
      const response = await httpFacade.rooms.fetchRooms(
        this.event.priceGroupBooking,
      );

      this.rooms = response.data;
    } catch (error) {
      Log.warn(error);
    }
  }

  @action.bound
  async submit() {
    this.pending = true;

    try {
      let eventId = this.event.id;
      if (this.event.id) {
        await this.editEvent();
      } else {
        const result = await this.createEvent();
        eventId = result.data.id;
      }

      AppRouter.push(replaceUrlParams(ROUTES.event, { id: eventId }));
    } catch (error) {
      if (
        error.status === 409 &&
        error.data.errors.some(
          er =>
            er.message ===
            'error.business.event.conflict.roomIsAlreadyReserved',
        )
      ) {
        try {
          await ModalStore.showModal(ConfirmModal, {
            text: 'error.business.event.conflict.roomIsAlreadyReserved',
            okBtnText: 'button.ok',
            cancelBtnText: 'booking.error.modal.confirm.button.toRoomSelection',
          });
        } catch (error) {
          this.stepper.setActiveStep(0)();
        }
      } else {
        showHttpErrors(error);
      }
    }

    this.pending = false;
  }

  @action.bound
  async reset() {
    try {
      await ModalStore.showModal(PreventModal, {
        message: 'modal.cancelCreateEvent.message',
        continueText: 'modal.cancelCreateEvent.button.confirm',
        canceledText: 'modal.cancelCreateEvent.button.cancel',
      });

      this.event = new EventModel();
      this.selectRoom.reset();

      this.stepper.setActiveStep(0)();

      if (this.eventID) {
        await this.init();
      } else {
        await this.selectRoom.init(this.startDate);
      }
    } catch (error) {
      Log.warn(error);
    }
  }
}

export default BookingStore;
