import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';
import classNames from 'classnames';

import { Roles } from 'helpers/roles';
import RootStore from 'stores/RootStore';
import { CreateUserStore } from 'stores/CreateUserStore';
import { DialogActionType, ModalProps } from 'stores/ModalStore';

import Select from 'components/Form/Fields/Select/Select';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/TextField/TextField';
import Button from 'components/Button/Button';
import AlertModal from 'components/Modals/AlertModal/AlertModal';

import style from './CreateUserModal.module.scss';

interface Props extends ModalProps {
  onUserCreated: () => void;
}

@observer
class CreateUserModal extends Component<Props> {
  store = new CreateUserStore({ userRole: Roles.SECRETARY });
  formControl = bindFormControl(this.store.form);

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  onSubmit = async event => {
    event.preventDefault();
    await this.store.createUser(() => {
      this.props.onUserCreated();
      this.props.onClose(DialogActionType.submit);
    });
  };

  async componentDidMount() {
    await this.store.init();
  }

  render() {
    const FormControl = this.formControl;
    const intl = RootStore.localization.intl;
    const { form } = this.store;
    const placeholderMandatory = intl.formatMessage({
      id: 'user.form.placeholder.mandatory',
    });

    return (
      <>
        <Modal
          className={classNames(style.modal, RootStore.config.appSchema)}
          destroyOnClose={true}
          onClose={this.onClose}
          animation="zoom"
          maskAnimation="fade"
          visible
        >
          <form onSubmit={this.onSubmit}>
            <h3 className={style.title}>
              <FormattedMessage id="title.newUser" />
            </h3>
            <div className={style.body}>
              <FormControl
                name="firstName"
                className={style.formControl}
                render={props => (
                  <TextField {...props} placeholder={placeholderMandatory} />
                )}
              />
              <FormControl
                name="lastName"
                className={style.formControl}
                render={props => (
                  <TextField {...props} placeholder={placeholderMandatory} />
                )}
              />
              <FormControl
                name="email"
                className={style.formControl}
                render={props => (
                  <TextField {...props} placeholder={placeholderMandatory} />
                )}
              />
              <div className={style.formControl}>
                <label className={style.select__title}>
                  <FormattedMessage id="title.priceGroup" />
                </label>
                <Select
                  name="priceGroup"
                  value={form.priceGroup}
                  options={this.store.priceGroupsOptions}
                  classNamePrefix="select"
                  className={style.select}
                  onChange={form.setPriceGroup}
                />
              </div>
            </div>

            <div className={style.form__controls}>
              <Button
                styleType="ghost"
                onClick={this.onClose}
                className={style.btn}
              >
                <FormattedMessage id="button.abort" />
              </Button>

              <Button
                type="submit"
                styleType="accept"
                className={style.btn}
                disabled={!this.store.form.isFormValid}
              >
                <FormattedMessage id={'button.create'} />
              </Button>
            </div>
          </form>
        </Modal>

        {this.store.serverError && (
          <AlertModal
            message={this.store.serverError}
            onClose={this.store.resetErrors}
          />
        )}
      </>
    );
  }
}

export { CreateUserModal };
