export const DEFAULT_INVOICE_SORTING = {
  id: 'start',
  desc: false,
};

export const DEFAULT_EVENT_SORTING = {
  id: 'start',
  desc: false,
};

export const DEFAULT_USERS_SORTING = {
  id: 'firstName',
  desc: true,
};

export const DEFAULT_CATEGORIES_SORTING = {
  id: 'title',
  desc: false,
};
