import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isEmail(msg?: string) {
  const regEx = /^([\w.-]+@([\w.-]+)\.+\w{2,})$/;
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];

        const isValid = !value || regEx.test(value);
        return isValid
          ? undefined
          : {
              message: msg || 'VM_IS_EMAIL',
              data: {
                name,
                value,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
