import React, { Component } from 'react';
import classNames from 'classnames';

import style from './InvoiceCard.module.scss';

import { EventReservation, InvoiceStatus } from 'types/entities';
import { FormattedDate } from 'react-intl';

interface Props {
  invoice: any;
  className?: string;
}

class InvoiceCard extends Component<Props> {
  render() {
    const { invoice, className } = this.props;

    return (
      <div
        className={classNames(style.invoice, className, {
          [style.invoice__accept]: invoice.status === InvoiceStatus.accepted,
          [style.invoice__reject]: invoice.status === InvoiceStatus.declined,
          [style.invoice__inApproval]:
            invoice.status === InvoiceStatus.inApproval,
        })}
      >
        <div className={style.description}>
          <div className={style.time}>
            <FormattedDate
              value={invoice.event.start}
              year="numeric"
              month="2-digit"
              day="2-digit"
            />
          </div>

          <div className={style.name}>{invoice.event.title}</div>
          <div className={style.room}>
            {invoice.event.reservations.map((reservation: EventReservation) => (
              <p key={reservation.id}>{reservation.room.title}</p>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceCard;
