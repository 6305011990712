import React, { ReactNode } from 'react';
import classNames from 'classnames';

import style from './RoomRow.module.scss';

interface IProps {
  roomData: {
    title: string;
    arrangement: string;
  };
  children: ReactNode;
  type?: 'info' | 'default' | 'active';
  className?: string;
}

export function RoomRow({
  roomData,
  type = 'default',
  children,
  className,
}: IProps) {
  return (
    <div
      className={classNames(style.room, className, {
        [style.room__typeInfo]: type === 'info',
        [style.room__typeActive]: type === 'active',
      })}
    >
      <div className={style.room__info}>
        <div className={style.room__title}>{roomData.title}</div>
        <div className={style.room__seating}>{roomData.arrangement}</div>
      </div>
      {children}
    </div>
  );
}
