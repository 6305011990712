import { dateAdd, dateStartOf } from './datetime';

import { Event, EventStatus, InvoiceStatus, Invoice } from 'types/entities';

export enum FilterPeriods {
  thisMonth = 'THIS_MONTH',
  pastMonths = 'PAST_MONTH',
  thisWeek = 'THIS_WEEK',
  nextWeek = 'NEXT_WEEK',
  later = 'LATER',
  past = 'PAST',
  today = 'TODAY',
  tomorrow = 'TOMORROW',
}

export enum EventStatuses {
  all = 'ALL',
  inApproval = 'IN_APPROVAL',
  approved = 'APPROVED',
  declined = 'DECLINED',
  cancelled = 'CANCELLED',
}

export enum InvoiceStatuses {
  all = 'ALL',
  draft = 'DRAFT',
  inApproval = 'IN_APPROVAL',
  approved = 'APPROVED',
  declined = 'DECLINED',
}

export function filterByStatus(status: EventStatuses | string, event: Event) {
  switch (status) {
    case EventStatuses.inApproval:
      return event.status === EventStatus.inApproval;
    case EventStatuses.approved:
      return (
        event.status === EventStatus.accepted ||
        event.status === EventStatus.finished
      );
    case EventStatuses.declined:
      return event.status === EventStatus.declined;
    case EventStatuses.cancelled:
      return event.status === EventStatus.closed;
    case EventStatuses.all:
    default:
      return true;
  }
}

export function filterByPeriod(period: FilterPeriods | string, event: Event) {
  const now = new Date();

  switch (period) {
    case FilterPeriods.later:
      return event.start >= now;
    case FilterPeriods.past:
      return event.start < now;
    case FilterPeriods.nextWeek:
      return (
        event.start >= dateAdd(dateStartOf(now, 'week'), 1, 'week') &&
        event.start < dateAdd(dateStartOf(now, 'week'), 2, 'week')
      );
    case FilterPeriods.today:
      return (
        event.start >= dateStartOf(now, 'day') &&
        event.start < dateAdd(dateStartOf(now, 'day'), 1, 'day')
      );
    case FilterPeriods.tomorrow:
      return (
        event.start >= dateAdd(dateStartOf(now, 'day'), 1, 'day') &&
        event.start < dateAdd(dateStartOf(now, 'day'), 2, 'day')
      );
    case FilterPeriods.thisWeek:
    default:
      return (
        event.start >= dateStartOf(now, 'week') &&
        event.start < dateAdd(dateStartOf(now, 'week'), 1, 'week')
      );
  }
}

export function filterInvoicesByStatus(
  status: InvoiceStatuses | string,
  invoice: Invoice,
) {
  switch (status) {
    case InvoiceStatuses.inApproval:
      return invoice.status === InvoiceStatus.inApproval;
    case InvoiceStatuses.approved:
      return invoice.status === InvoiceStatus.accepted;
    case InvoiceStatuses.declined:
      return invoice.status === InvoiceStatus.declined;
    case InvoiceStatuses.draft:
      return invoice.status === InvoiceStatus.draft;
    case InvoiceStatuses.all:
    default:
      return true;
  }
}
