import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './ManagerPage.module.scss';

import EventCard from 'components/EventCard/EventCard';
import Spinner from 'components/Spinner/Spinner';
import ManagerDashboardStore, { EventTime } from 'stores/ManagerDashboardStore';

@observer
class Tabs extends Component<{ store: ManagerDashboardStore }> {
  changeTab = filter => () => {
    this.props.store.eventTime = filter;
  };

  render() {
    const { store } = this.props;

    const tabs = [
      {
        name: 'state.date.upcoming',
        value: store.upcomingEventsAmount,
        accessor: EventTime.upcoming,
      },
      {
        name: 'state.date.ongoing',
        value: store.ongoingEventsAmount,
        accessor: EventTime.ongoing,
      },
      {
        name: 'state.date.finished',
        value: store.finishedEventsAmount,
        accessor: EventTime.finished,
      },
    ];

    return store.pending ? (
      <Spinner />
    ) : (
      <section className={style.tabs_section}>
        <ul className={style.tabs}>
          {tabs.map((item, index) => (
            <li
              className={classNames(style.tab__item, {
                [style.tab__active]: store.eventTime === item.accessor,
              })}
              key={index}
              onClick={this.changeTab(item.accessor)}
            >
              <FormattedMessage id={item.name} />
              <span className={style.tab__value}>{item.value}</span>
            </li>
          ))}
        </ul>

        <div className={style.invoices}>
          {store.data.map(event => (
            <EventCard event={event} key={event.id} className={style.invoice} />
          ))}
        </div>
      </section>
    );
  }
}

export default Tabs;
