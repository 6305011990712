import { observable } from 'mobx';

class StatisticModel {
  @observable thisWeekBookings: 0;
  @observable nextWeekBookings: 0;
  @observable laterBookings: 0;
  @observable openedInvoices: 0;
  @observable acceptedInvoices: 0;
  @observable declinedInvoices: 0;
}

export default StatisticModel;
