import React, { Component, ElementType } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import RootStore from 'stores/RootStore';
import Icon from 'components/Icon/Icon';
import style from './Select.module.scss';

export type SelectOnChangeType<T> = (
  v: T | undefined | null,
  option: Option<T> | undefined | null,
  name: string | undefined,
  payload?: unknown,
) => void;

export interface Option<T> {
  value: T;
  label: string | JSX.Element;
  payload?: unknown;
}

interface Props<T> {
  name?: string;
  className?: string;
  classNamePrefix?: string;
  value: T;
  onChange: SelectOnChangeType<T>;
  options: Array<Option<T>>;
  disabled?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  noOptionsMessage?: ElementType;
}

class Select<T = any> extends Component<Props<T>> {
  value = () =>
    this.props.options.find(option => option.value === this.props.value);

  onChange = (v: Option<T> | undefined | null) => {
    this.props.onChange(v && v.value, v, this.props.name, v && v.payload);
  };

  DropdownIndicator = () => (
    <Icon type="arrowBottom" className={style.indicator} />
  );

  isOptionDisabled = option => !!option.disabled;

  componentDidUpdate(prevProps) {
    const newValue = this.value();

    // trigger onChange if changed initial value from props
    if (!prevProps.value && this.props.value && newValue) {
      this.onChange({
        label: newValue.label,
        value: newValue.value,
        payload: newValue.payload,
      });
    }
  }

  render() {
    const {
      value,
      onChange,
      disabled,
      className,
      isSearchable = false,
      isLoading = false,
      noOptionsMessage,
      ...props
    } = this.props;
    const intl = RootStore.localization.intl;

    return (
      <ReactSelect
        placeholder={intl.formatMessage({
          id: 'SELECT_PLACEHOLDER',
        })}
        value={this.value()}
        styles={{
          menu: (provided, _state) => ({
            ...provided,
            zIndex: 15,
          }),
        }}
        isOptionDisabled={this.isOptionDisabled}
        isDisabled={disabled}
        {...props}
        className={classNames(style.select, className)}
        classNamePrefix="select"
        onChange={this.onChange as any}
        components={{
          DropdownIndicator: this.DropdownIndicator,
        }}
        isSearchable={isSearchable}
        isLoading={isLoading}
        // @ts-ignore
        noOptionsMessage={noOptionsMessage}
      />
    );
  }
}

export default Select;
