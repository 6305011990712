import http from '../http';

import { stringToDateTime, dateToString } from 'helpers/datetime';
import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import { InvoiceStatus, Event } from 'types/entities';

interface Invoice {
  comment?: string;
  event: Event & { start: string };
  id: string;
  number: string;
  status: InvoiceStatus;
  version?: number;
}

type FetchInvoicesResponse = Invoice[];

interface ChangeStatusRequestBody {
  version: number;
}

interface CreateInvoiceRequestData extends ChangeStatusRequestBody {
  comment: string;
  eventId: string;
}

interface DeclineInvoiceRequestData extends ChangeStatusRequestBody {
  comment: string;
}

export const invoicesApi = {
  fetchInvoices(date: Date) {
    const dateString = dateToString(date);

    return http
      .get<FetchInvoicesResponse>(appUrl(URLS.invoices), {
        params: {
          startDate: dateString,
        },
      })
      .then(response => ({
        ...response,
        data: response.data.map(invoice => ({
          ...invoice,
          event: {
            ...invoice.event,
            start: stringToDateTime(invoice.event.start),
          },
        })),
      }));
  },

  fetchInvoicesForPeriod(startDate: Date, endDate: Date) {
    const startDateString = dateToString(startDate);
    const endDateString = dateToString(endDate);

    return http
      .get<FetchInvoicesResponse>(appUrl(URLS.invoicesForPeriod), {
        params: {
          startDate: startDateString,
          endDate: endDateString,
        },
      })
      .then(response => ({
        ...response,
        data: response.data.map(invoice => ({
          ...invoice,
          event: {
            ...invoice.event,
            start: stringToDateTime(invoice.event.start),
          },
        })),
      }));
  },

  // DEPRECATED!!!
  createInvoice(data: CreateInvoiceRequestData) {
    return http.post<CreateInvoiceRequestData, unknown>(
      appUrl(URLS.invoices),
      data,
    );
  },

  acceptInvoice(id: string, version: number) {
    return http.put<ChangeStatusRequestBody, unknown>(
      appUrl(URLS.invoiceAccept),
      { version },
      {
        replaceParams: { id },
      },
    );
  },

  approveInvoice(id: string, version: number) {
    return http.put<ChangeStatusRequestBody, unknown>(
      appUrl(URLS.invoiceApprove),
      { version },
      {
        replaceParams: { id },
      },
    );
  },

  declineInvoice(id: string, comment: string, version: number) {
    return http.put<DeclineInvoiceRequestData, unknown>(
      appUrl(URLS.invoiceDecline),
      { comment, version },
      {
        replaceParams: { id },
      },
    );
  },

  fetchInvoiceReport(id: string) {
    return http.get<any>(appUrl(URLS.invoiceReport), {
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
      replaceParams: { id },
    });
  },

  fetchEventReport(id: string) {
    return http.get<any>(appUrl(URLS.eventReport), {
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
      replaceParams: { id },
    });
  },
};
