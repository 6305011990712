import { action, observable } from 'mobx';

import httpFacade from 'http/httpFacade';
import { toPairs } from 'helpers/array';
import { showHttpErrors } from 'helpers/errors';
import { Roles } from '../helpers/roles';
import RootStore from './RootStore';

import StatisticModel from './Models/StatisticModel';

export class StatisticsStore {
  @observable pending = false;
  @observable data = new StatisticModel();

  @action
  async init() {
    await this.fetchStatistics();
  }

  @action.bound
  async fetchStatistics() {
    this.pending = true;

    try {
      const response = RootStore.user.roles.includes(Roles.SECRETARY)
        ? await httpFacade.statistics.fetchSecretaryStatistics()
        : await httpFacade.statistics.fetchCatererStatistics();

      toPairs<any>(response.data).forEach(([key, value]) => {
        this.data[key] = value;
      });
    } catch (error) {
      showHttpErrors(error);
    }

    this.pending = false;
  }
}

export default StatisticsStore;
