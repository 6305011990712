import { Roles } from 'helpers/roles';

export type CurrencyValue = number;

export enum EventStatus {
  accepted = 'ACCEPTED',
  closed = 'CANCELED',
  declined = 'DECLINED',
  inApproval = 'IN_APPROVAL',
  finished = 'FINISHED',
}

export enum InvoiceStatus {
  accepted = 'ACCEPTED',
  declined = 'DECLINED',
  draft = 'DRAFT',
  inApproval = 'IN_APPROVAL',
}

export interface User {
  address?: UserAddressDto;
  company?: string;
  department?: string;
  email: string;
  firstName: string;
  id: string;
  kostenStelleNr?: string;
  lastName: string;
  nickname?: string;
  priceGroup: UserPriceGroup;
  roles: UserRole[];
}

export interface UserRole {
  id: string;
  name: Roles;
}

export interface UserAddressDto {
  additionalAddress?: string;
  country?: string;
  postCode?: string;
  street?: string;
  town?: string;
}

export interface Room {
  id: string;
  title: string;
  description: string;
  arrangements: Arrangement[];
  images: string[];
  equipments: string[];
  priceTiers: RoomPrice | null;
  preparation: number;
  cleaning: number;
}

export interface EventReservation {
  id: string;
  arrangement: Arrangement;
  cleaning: number;
  description: string;
  menu: MenuItem[];
  participants: string;
  preparation: number;
  room: Room;
  roomPrice?: number;
}

export enum MenuItemType {
  Drink = 'DRINK',
  Food = 'FOOD',
}

export interface Allergen {
  id: string;
  title: string;
  icon?: string;
}

export interface Secretary {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string | null;
  kostenStelleNr: string | null;
  roles: Array<{ id: string; name: string }>;
}

export interface MenuSet {
  id: string;
  image: string;
  title: string;
  description: string;
  detailedDescription: string;
  favorite: boolean;
  category: {
    id: string;
    title: string;
    type: MenuItemType;
  };
  allergens: Allergen[];
  price: CurrencyValue;
  isParticipantsRequired?: boolean;
}

export interface MenuItem extends MenuSet {
  amount: number;
  extra: boolean;
}

export interface MenuCategory {
  id: string;
  title: string;
  type: MenuItemType;
}

export interface Category {
  amount: number;
  brutto: number;
  netto: number;
  profit: number;
  purchase: number;
  title: string;
  priceGroup: UserPriceGroup;
  room: boolean;
}

export interface Event {
  createdById: string;
  canEdit: boolean;
  canEditCatering: CanEditCateringType;
  cleaning: number;
  comment?: string;
  duplicated: boolean;
  duration: number;
  id: string;
  invoice?: {
    id: string;
    number: string;
    status: string;
  };
  personAmount: number;
  preparation: number;
  priceGroupBooking: UserPriceGroup;
  reservations: EventReservation[];
  start: Date;
  status: EventStatus;
  totalPrice: CurrencyValue;
  title: string;
  version?: number;
  roomServiceCost?: CurrencyValue;
  nextGenId?: string;
}

export interface Invoice {
  comment?: string;
  event: Event;
  id: string;
  number: string;
  status: InvoiceStatus;
  version?: number;
}

export interface Arrangement {
  id: string;
  image: string;
  title: string;
  capacity: number;
  description: string;
}

export interface RoomPrice {
  tiers: PriceTier[];
}

export interface PriceTier {
  maxDurationMins: number;
  price: CurrencyValue;
}

export enum CanEditCateringType {
  none = 'NONE',
  drink = 'DRINK',
  all = 'ALL',
}

export interface SortConfig {
  accessor: string | ((record: any) => any);
  desc: boolean;
}

export interface ReportFilter {
  [key: string]: string | number;
}

export enum BookingType {
  ANY = 'ANY',
  WITH_CATERING = 'WITH_CATERING',
  ONLY_ROOM = 'ONLY_ROOM',
}

export enum UserPriceGroup {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  AUDI = 'AUDI',
}
