import React from 'react';
import { FormattedMessage } from 'react-intl';

import ModalStore from 'stores/ModalStore';

import Button from 'components/Button/Button';
import { CreateUserModal } from 'components/Modals/CreateUserModal/CreateUserModal';

import style from './UserSelect.module.scss';

interface Props {
  onUserCreated: () => void;
}

export function UserSelectNoOptionsMessage({ onUserCreated }: Props) {
  const onClick = () => {
    ModalStore.showModal(CreateUserModal, {
      onUserCreated,
    });
  };

  return (
    <div className={style.noOptionsMessage}>
      <div className={style.text}>
        <p className={style.message}>
          <FormattedMessage id="select.users.noUser" />
        </p>
        <p className={style.message}>
          <FormattedMessage id="select.users.createUserMessage" />
        </p>
      </div>
      <Button styleType="ghost" onClick={onClick}>
        <FormattedMessage id="select.users.button.createUser" />
      </Button>
    </div>
  );
}
