import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import classNames from 'classnames';

import style from './Planner.module.scss';

import NumberField from 'components/Form/Fields/NumberField/NumberField';
import Select from 'components/Form/Fields/Select/Select';

import { dateAdd, getTime, Time } from 'helpers/datetime';
import { getRole, Roles } from 'helpers/roles';
import RootStore from 'stores/RootStore';
import BookingStore from 'stores/Booking/BookingStore';

import Icon from 'components/Icon/Icon';
import InnerCalendar from 'components/InnerCalendar/InnerCalendar';
import {
  tileDisabled,
  tileHolidayClassName,
} from 'components/InnerCalendar/helpers';
import UserSelect from 'components/UserSelect/UserSelect';
import SearchField from 'components/Form/Fields/Search/SearchField';

interface Props extends WrappedComponentProps {
  bookingStore: BookingStore;
}

function scrollToTime(time: Time, offsetYTime: number) {
  const scrollTime = dateAdd(new Date(time), -offsetYTime, 'minute');
  const hour = scrollTime.getUTCHours();
  const minutes = scrollTime.getUTCMinutes();
  const container = document.getElementsByClassName('rbc-time-content')[0];
  const element = document.getElementById(`timeSlot${hour}_${minutes}`);

  if (container && element) {
    const start = container.scrollTop;
    const finish = element.offsetTop;
    const speed = (finish - start) / 300;

    let startTime;

    requestAnimationFrame(function moveScroll(tick) {
      if (!startTime) {
        startTime = tick;
        requestAnimationFrame(moveScroll);
      } else {
        const current = start + speed * (tick - startTime);
        container.scrollTop =
          (current < finish && speed < 0) || (current > finish && speed > 0)
            ? finish
            : current;

        if (
          (current < finish && speed > 0) ||
          (current > finish && speed < 0)
        ) {
          requestAnimationFrame(moveScroll);
        }
      }
    });
  }
}

@observer
class Filter extends Component<Props> {
  scrollTimePosition: number;

  componentDidMount() {
    const { bookingStore } = this.props;

    if (this.scrollTimePosition !== this.props.bookingStore.selectRoom.time) {
      scrollToTime(
        bookingStore.selectRoom.time,
        bookingStore.maxPreparationDuration,
      );
      this.scrollTimePosition = this.props.bookingStore.selectRoom.time;
    }
  }

  componentDidUpdate() {
    const { bookingStore } = this.props;

    if (this.scrollTimePosition !== this.props.bookingStore.selectRoom.time) {
      scrollToTime(
        bookingStore.selectRoom.time,
        bookingStore.maxPreparationDuration,
      );
      this.scrollTimePosition = this.props.bookingStore.selectRoom.time;
    }
  }

  @computed get timeOptionValues() {
    return this.props.bookingStore.selectRoom.timeOptionValues.map(option => ({
      value: option.value,
      label: this.props.intl.formatTime(option.value, {
        timeZone: 'UTC',
      }),
      disabled: option.disabled,
    }));
  }

  @computed get durationOptionValues() {
    return this.props.bookingStore.selectRoom.durationOptionValues.map(
      option => ({
        value: option.value,
        label: this.props.intl.formatTime(getTime(0, option.value), {
          timeZone: 'UTC',
        }),
      }),
    );
  }

  render() {
    const { bookingStore } = this.props;
    const { eventTimeMin, eventDurationMin } = RootStore.config;
    const isMasterBooking = getRole(Roles.MASTER_BOOKING);

    return (
      <div className={style.filterWrapper}>
        <div className={classNames(style.filterControlsLine, style.filterDate)}>
          <InnerCalendar
            onChange={v => (bookingStore.selectRoom.date = v)}
            value={bookingStore.selectRoom.date}
            light
            minDate={new Date()}
            tileDisabled={tileDisabled}
            tileClassName={tileHolidayClassName}
            className={style.calendar}
          />
        </div>
        {isMasterBooking && (
          <>
            <div className={style.selectUserMessage}>
              <FormattedMessage id="booking.selectUser.message" />
            </div>
            <div className={style.filterControlsLine}>
              <Icon type="user" className={style.icon} />
              <UserSelect
                userId={bookingStore.event.owner.id}
                onChangeUserId={bookingStore.event.setOwner}
                disabled={!!bookingStore.eventID}
              />
            </div>
          </>
        )}
        <div className={style.filterControlsLine}>
          <Icon type="time" className={style.icon} />
          <Select
            className={style.select}
            value={bookingStore.selectRoom.time}
            onChange={value => {
              bookingStore.selectRoom.time =
                value == null ? eventTimeMin : value;
            }}
            options={this.timeOptionValues}
          />
          <Select
            className={style.select}
            value={bookingStore.selectRoom.duration}
            onChange={v => {
              bookingStore.selectRoom.duration = v || eventDurationMin;
            }}
            options={this.durationOptionValues}
          />
        </div>
        <div className={style.filterControlsLine}>
          <Icon type="users" className={style.icon} />
          <NumberField
            className={style.field__bookingFilter}
            min={1}
            max={99999}
            value={bookingStore.event.personAmount}
            onChange={v => bookingStore.selectRoom.setPersonAmount(Number(v))}
            notEmpty
          />
        </div>
        <SearchField
          onChange={bookingStore.selectRoom.setSearch}
          value={bookingStore.selectRoom.searchValue}
          className={style.roomSearch}
        />
      </div>
    );
  }
}

export default injectIntl(Filter);
