import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from 'routes/routes';
import EventsStore from 'stores/EventsStore';
import AppRouter from 'stores/AppRouter';
import { Event } from 'types/entities';

import Grid from 'components/Grid/Grid';
import EventItem from 'components/Grid/Items/EventItem';
import { Types } from 'components/Grid/types';
import { DEFAULT_EVENT_SORTING } from 'pages/config';

import style from './StaffDashboard.module.scss';
import Select from 'components/Form/Fields/Select/Select';
import StorageStore, { FILTERS } from 'stores/FiltersStore';
import { FilterPeriods } from 'helpers/filters';

export const eventConfig: Types<Event> = {
  columns: [
    {
      header: <FormattedMessage id="title.time" />,
      id: 'start',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.event" />,
      id: 'title',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.room" />,
      id: 'room.title',
      sortable: false,
    },
    {
      header: <FormattedMessage id="title.personAmount" />,
      id: 'personAmount',
      sortable: true,
    },
  ],
  item: EventItem,
};

@observer
class StaffDashboard extends Component {
  store = new EventsStore();
  periodOptions = [
    {
      value: FilterPeriods.today,
      label: <FormattedMessage id="options.today" />,
    },
    {
      value: FilterPeriods.tomorrow,
      label: <FormattedMessage id="options.tomorrow" />,
    },
    {
      value: FilterPeriods.thisWeek,
      label: <FormattedMessage id="options.thisWeek" />,
    },
    {
      value: FilterPeriods.nextWeek,
      label: <FormattedMessage id="options.nextWeek" />,
    },
  ];

  async componentDidMount() {
    const period = StorageStore.getFilter(FILTERS.GRID__EVENTS__PERIOD);
    if (period) {
      this.store.period = period;
    }

    await this.store.fetchEvents();
  }

  onItemClick = item => {
    AppRouter.push(ROUTES.event, { id: item.id });
  };

  changePeriod = value => {
    StorageStore.saveFilter(FILTERS.GRID__EVENTS__PERIOD, value);

    this.store.period = value;
  };

  changeStatus = value => {
    StorageStore.saveFilter(FILTERS.GRID__EVENTS__STATUS, value);

    this.store.status = value;
  };

  render() {
    return (
      <>
        <div className={style.navigation} />

        <div className={style.filters}>
          <FormattedMessage id="eventsList.filters.title" />

          <Select
            className={style.select}
            value={this.store.period}
            onChange={this.changePeriod}
            options={this.periodOptions}
            disabled={this.store.pending}
          />
        </div>

        <div className={style.content}>
          <Grid
            loading={this.store.pending}
            data={this.store.data}
            config={eventConfig}
            className={style.grid__booking}
            onRowClick={this.onItemClick}
            onSort={config => (this.store.sortConfig = config)}
            defaultSorted={DEFAULT_EVENT_SORTING}
            expandable={true}
          />
        </div>
      </>
    );
  }
}

export default StaffDashboard;
