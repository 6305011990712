import http from '../http';

import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import RoomModel from 'stores/Models/RoomModel';
import { Room, UserPriceGroup } from 'types/entities';

type FetchRoomsResponse = Room[];

export const roomsApi = {
  fetchRooms(priceGroup: UserPriceGroup) {
    return http
      .get<FetchRoomsResponse>(appUrl(URLS.rooms), {
        params: {
          priceGroup,
        },
      })
      .then(response => ({
        ...response,
        data: response.data.map(room => new RoomModel(room)),
      }));
  },
};
