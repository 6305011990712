import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './EventPage.module.scss';

import Icon from 'components/Icon/Icon';
import { EventStatus } from 'types/entities';

interface Props {
  status: EventStatus;
  className?: string;
}

function getTitleIdByStatus(status: EventStatus) {
  switch (status) {
    case EventStatus.accepted:
      return 'event.status.accepted.text';
    case EventStatus.closed:
      return 'event.status.closed.text';
    case EventStatus.declined:
      return 'event.status.declined.text';
    case EventStatus.inApproval:
      return 'event.status.inApproval.text';
    case EventStatus.finished:
      return 'event.status.finished.text';
    default:
      return String(status);
  }
}

const StatusBar = (props: Props) => {
  const { status, className } = props;

  let StatusIcon;

  switch (status) {
    case EventStatus.accepted:
      StatusIcon = <Icon type="accept" className={style.icon__status} />;
      break;

    case EventStatus.declined:
      StatusIcon = <Icon type="declined" className={style.icon__status} />;
      break;

    case EventStatus.inApproval:
      StatusIcon = <Icon type="reload" className={style.icon__status} />;
      break;
  }

  return (
    <div
      className={classNames(style.status, className, {
        [style.status__accepted]: EventStatus.accepted === status,
        [style.status__rejected]: EventStatus.declined === status,
        [style.status__processing]: EventStatus.inApproval === status,
        [style.status__closed]: EventStatus.closed === status,
        [style.status__finished]: EventStatus.finished === status,
      })}
    >
      {StatusIcon}
      <FormattedMessage id={getTitleIdByStatus(status)} />
    </div>
  );
};

export default StatusBar;
