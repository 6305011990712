import React, { Component } from 'react';
import { FormattedTime } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import style from './EventCard.module.scss';

import { Event, EventStatus } from 'types/entities';

import { dateAdd } from 'helpers/datetime';

import { replaceUrlParams } from 'http/helpers';

import { ROUTES } from 'routes/routes';

interface Props {
  event: Event;
  className?: string;
}

class EventCard extends Component<Props> {
  render() {
    const { event, className } = this.props;

    return (
      <Link
        to={replaceUrlParams(ROUTES.event, { id: event.id })}
        className={classNames(style.event, className, {
          [style.event__inApproval]: event.status === EventStatus.inApproval,
          [style.event__accept]: event.status === EventStatus.accepted,
          [style.event__finished]: event.status === EventStatus.finished,
          [style.event__declined]: event.status === EventStatus.declined,
          [style.event__canceled]: event.status === EventStatus.closed,
        })}
      >
        <div className={style.description}>
          <div className={style.time}>
            <FormattedTime value={event.start} />
            {' - '}
            <FormattedTime
              value={dateAdd(event.start, event.duration, 'minute')}
            />
          </div>
          <div className={style.room}>
            {event.reservations.map(reservation => (
              <p key={reservation.room.id}>{reservation.room.title}</p>
            ))}
          </div>
          <div className={style.name}>{event.title}</div>
        </div>
      </Link>
    );
  }
}

export default EventCard;
