import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useAlert(): [string, Dispatch<SetStateAction<string>>] {
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAlertMessage('');
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [alertMessage]);

  return [alertMessage, setAlertMessage];
}
